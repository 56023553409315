import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import {
  MIDPOINT_COLOR,
  BLACK,
  POINT_COLOR,
  SOLAR_POINT_COLOR,
  RESTRICTED_AREA_COLOR,
  ZOOM_FACTOR,
  RENDERING_ORDER,
} from "../constants";
import snapCursor from "@/assets/model/snap_cursor.svg";
import trashRed from "@/assets/model/trash_red.svg";

export const createReactivePoint = function (
  referencePoint,
  isMeasurement,
  addSnapIcon = false
) {
  const color = isMeasurement ? POINT_COLOR : SOLAR_POINT_COLOR;

  const wrapperMaterial = new THREE.MeshBasicMaterial({
    color: BLACK,
    transparent: true,
  });
  const wrapper = new THREE.Mesh(this.dotGeometry, wrapperMaterial);

  wrapper.position.copy(referencePoint);
  wrapper.material.depthTest = false;
  wrapper.renderOrder = RENDERING_ORDER.OUTER_POINT;

  const innerDotMaterial = new THREE.MeshBasicMaterial({
    color: color,
    transparent: true,
  });
  const innerDot = new THREE.Mesh(this.dotGeometry, innerDotMaterial);
  innerDot.material.depthTest = false;
  innerDot.renderOrder = RENDERING_ORDER.INNER_POINT;

  wrapper.add(innerDot);

  if (addSnapIcon) {
    const icon = this.createSnapIcon();
    wrapper.add(icon);
  }

  const update = () => {
    const distance = wrapper.position.distanceTo(this.camera.position);

    const wrapperScaleFactor = (1 / (ZOOM_FACTOR + 10)) * distance;
    const innerDotScaleFactor = (1 / ZOOM_FACTOR) * distance;
    const relativeInnerDotScaleFactor =
      innerDotScaleFactor / wrapperScaleFactor;

    wrapper.scale.set(
      wrapperScaleFactor,
      wrapperScaleFactor,
      wrapperScaleFactor
    );
    innerDot.scale.set(
      relativeInnerDotScaleFactor,
      relativeInnerDotScaleFactor,
      relativeInnerDotScaleFactor
    );

    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(update);
  };

  update();

  wrapper.setPointColor = (color) => {
    innerDot.material.color.setHex(color);
  };

  return wrapper;
};

export const createSnapIcon = function () {
  const iconHtml = `<img class="snap-icon" src="${snapCursor}" />`;
  const iconElement = document.createElement("div");
  iconElement.innerHTML = iconHtml;
  iconElement.style.visibility = "hidden";

  const iconLabel = new CSS2DObject(iconElement);

  return iconLabel;
};

export const createLabelBetweenTwoPoints = function (
  firstPoint,
  secondPoint,
  spacing,
  removable = false,
  line,
  offset = false
) {
  const distance = secondPoint.distanceTo(firstPoint);
  let div = document.createElement("div");
  div.className = "measurementLabel";
  div.distance = distance;

  if (removable) {
    let span = document.createElement("span");
    span.textContent = `${distance.toFixed(2)}`;

    let img = document.createElement("img");
    img.src = trashRed;
    img.className = "q-ml-xs cursor-pointer";
    img.style.width = "15px";
    img.style.display = "none";

    div.appendChild(span);
    div.appendChild(img);

    div.style.cursor = "pointer";
    div.addEventListener("pointerdown", () => {
      this.showMeasurement(line.id);
    });

    img.addEventListener("pointerdown", () => {
      this.deleteMeasurement(line.id);
    });
  } else {
    div.textContent = `${distance.toFixed(2)}`;
  }
  let label = new CSS2DObject(div);
  label.position.lerpVectors(secondPoint, firstPoint, spacing);

  if (offset) {
    const direction = new THREE.Vector3()
      .subVectors(secondPoint, firstPoint)
      .normalize();

    const up = new THREE.Vector3(0, -1, 0);
    const perpendicular = new THREE.Vector3()
      .crossVectors(direction, up)
      .normalize();

    label.position.addScaledVector(perpendicular, 0.5);
  }

  label.layers.set(0);
  label.renderOrder =
    RENDERING_ORDER.MEASUREMENT_LABEL + this.annotationRenderOrder;
  this.annotationRenderOrder += 1;

  return label;
};

export const updateLabelBetweenTwoPoints = function (
  label,
  firstPoint,
  secondPoint,
  spacing,
  offset = false,
  removable = false
) {
  const distance = secondPoint.distanceTo(firstPoint);
  const div = label.element;
  div.distance = distance;
  if (removable) {
    const span = document.createElement("span");
    span.textContent = `${distance.toFixed(2)}`;
    div.replaceChild(span, div.firstChild);
    span.addEventListener("pointerdown", () => {
      this.showMeasurementDetails(
        this.measurements[this.measurements.length - 1],
        true
      );
      this.measurementDetailsPersistent = true;
      this.measurementLabelClicked = true;
    });
  } else {
    div.textContent = `${distance.toFixed(2)}`;
  }
  label.position.lerpVectors(secondPoint, firstPoint, spacing);

  if (offset) {
    const direction = new THREE.Vector3()
      .subVectors(secondPoint, firstPoint)
      .normalize();

    const up = new THREE.Vector3(0, -1, 0);
    const perpendicular = new THREE.Vector3()
      .crossVectors(direction, up)
      .normalize();

    label.position.addScaledVector(perpendicular, 0.5);
  }
  return distance;
};

export const createReactiveThickLine = function (
  points,
  thickness,
  dashed = false,
  transparent = false,
  color = MIDPOINT_COLOR
) {
  const flatPoints = [];

  for (const vector of points) {
    flatPoints.push(vector.x, vector.y, vector.z);
  }
  let geometry = new LineGeometry();
  geometry.setPositions(flatPoints);

  const lineMaterial = new LineMaterial({
    color: color,
    linewidth: thickness,
    dashed,
    dashSize: 0.4,
    gapSize: 0.3,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
    transparent: true,
  });
  if (dashed) lineMaterial.defines.USE_DASH = "";
  if (transparent) lineMaterial.uniforms.opacity.value = 0.4;

  let line = new Line2(geometry, lineMaterial);
  line.computeLineDistances();

  line.material.depthTest = false;

  line.renderOrder = RENDERING_ORDER.LINE;

  return line;
};

export const updateLinePosition = function (line, points) {
  const flatPoints = [];

  for (const vector of points) {
    flatPoints.push(vector.x, vector.y, vector.z);
  }
  line.geometry.setPositions(flatPoints);

  line.computeLineDistances();
};

export const getCenterPoint = function (points) {
  const vectorPoints = points.map((point) => point.position);
  return this.getCenterPointFromVectors(vectorPoints);
};

export const getCenterPointFromVectors = function (points) {
  let centerX = 0;
  let centerY = 0;
  let centerZ = 0;

  for (let i = 0; i < points.length; i++) {
    centerX += points[i].x;
    centerY += points[i].y;
    centerZ += points[i].z;
  }

  centerX /= points.length;
  centerY /= points.length;
  centerZ /= points.length;

  return new THREE.Vector3(centerX, centerY, centerZ);
};

export const generateIndices = function (points) {
  const shape = new THREE.Shape();
  const numPoints = points.length;
  let indices = [];

  // Create the shape by connecting points in order
  shape.moveTo(points[0].x, points[0].y);
  for (let i = 1; i < numPoints; i++) {
    shape.lineTo(points[i].x, points[i].y);
  }

  // Triangulate the shape
  const triangles = THREE.ShapeUtils.triangulateShape(points, []);

  for (let i = 0; i < triangles.length; i++) {
    indices.push(triangles[i][0], triangles[i][1], triangles[i][2]);
  }
  return indices;
};

export const calculateTriangleArea = function (v1, v2, v3) {
  const e1 = new THREE.Vector3().subVectors(v2, v1);
  const e2 = new THREE.Vector3().subVectors(v3, v1);
  const crossProduct = new THREE.Vector3().crossVectors(e1, e2);
  const triangleArea = 0.5 * crossProduct.length();
  return triangleArea;
};

export const removeObjectFromScene = function (object) {
  const sceneObject = this.scene.getObjectById(object.id);
  this.scene.remove(sceneObject);
};

export const hideObjectFromScene = function (object) {
  if (!object) return;
  const sceneObject = this.scene.getObjectById(object.id);
  if (sceneObject) sceneObject.visible = false;
};

export const removeObjectWithChildrenFromScene = function (object) {
  if (object) {
    while (object.children.length > 0) {
      let child = object.children[0];
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
      object.remove(child);
    }
    // Remove the line group from the scene
    this.scene.remove(object);
  }
};

export const removeArrayFromScene = function (objects) {
  objects.forEach((object) => {
    const sceneObject = this.scene.getObjectById(object.id);
    this.scene.remove(sceneObject);
  });
};

export const disableClick = function (event) {
  if (this.isShiftDown || this.isAltDown || event.target.tagName !== "CANVAS")
    return true;
  else return false;
};

export const setMousePosition = function (event) {
  let canvasBounds = this.renderer.getContext().canvas.getBoundingClientRect();
  this.mouse.x =
    ((event.clientX - canvasBounds.left) /
      (canvasBounds.right - canvasBounds.left)) *
      2 -
    1;
  this.mouse.y =
    -(
      (event.clientY - canvasBounds.top) /
      (canvasBounds.bottom - canvasBounds.top)
    ) *
      2 +
    1;

  this.raycaster.setFromCamera(this.mouse, this.camera);
};

export const restoreDefaultCursor = function () {
  this.renderer.domElement.style.cursor = `default`;
};

export const displayAreaPoint = function (element, isMeasurement) {
  element.position.forEach((point, index) => {
    const areas = isMeasurement ? this.measurementAreas : this.areas;
    const isFirst = index === 0;
    if (isFirst) areas.push({ points: [], lines: [], element });

    const area = areas[areas.length - 1];
    const dot = this.createReactivePoint(point, isMeasurement);

    this.scene.add(dot);
    if (!isFirst) {
      let firstPoint = area.points[area.points.length - 1];
      let secondPoint = dot;
      const lineGroup = this.createLineGroup(
        firstPoint,
        secondPoint,
        isMeasurement,
        true
      );
      area.lines.push(lineGroup);
    }

    if (index === element.position.length - 1) {
      let firstPoint = dot;
      let secondPoint = area.points[0];
      const lineGroup = this.createLineGroup(
        firstPoint,
        secondPoint,
        isMeasurement,
        true
      );
      area.lines.push(lineGroup);
    }
    area.points.push(dot);
  });
};
export const displayRestrictedAreaPoint = function (element, detectedArea) {
  element.forEach((point, index) => {
    const isFirst = index === 0;
    if (isFirst)
      detectedArea.restrictedAreas.push({ points: [], lines: [], element });

    const area =
      detectedArea.restrictedAreas[detectedArea.restrictedAreas.length - 1];
    const dot = this.createReactivePoint(point, false);

    this.scene.add(dot);
    if (!isFirst) {
      let firstPoint = area.points[area.points.length - 1];
      let secondPoint = dot;

      const firstPointDynamic = new THREE.Vector3();
      const secondPointDynamic = new THREE.Vector3();
      firstPoint.getWorldPosition(firstPointDynamic);
      secondPoint.getWorldPosition(secondPointDynamic);
      let points = [firstPointDynamic, secondPointDynamic];

      const lineGroup = this.createReactiveThickLine(
        points,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );
      this.scene.add(lineGroup);

      const tempLineGroupe = {
        line: lineGroup,
        firstPoint,
        secondPoint,
      };
      area.lines.push(tempLineGroupe);
    }
    if (index === element.length - 1) {
      let firstPoint = dot;
      let secondPoint = area.points[0];

      const firstPointDynamic = new THREE.Vector3();
      const secondPointDynamic = new THREE.Vector3();
      firstPoint.getWorldPosition(firstPointDynamic);
      secondPoint.getWorldPosition(secondPointDynamic);
      let points = [firstPointDynamic, secondPointDynamic];

      const lineGroup = this.createReactiveThickLine(
        points,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );
      this.scene.add(lineGroup);
      const tempLineGroupe = {
        line: lineGroup,
        firstPoint,
        secondPoint,
      };
      area.lines.push(tempLineGroupe);
    }

    area.points.push(dot);
  });
};

export const clearMagnetEffect = function () {
  this.selectedPoint = null;
  this.inMagenticField = false;
};

export const showSnapIcon = function () {
  const snapIcon = this.selectedPoint.children[1];
  snapIcon.element.style.visibility = "visible";
};

export const hideSnapIcon = function () {
  const snapIcon = this.selectedPoint?.children[1];
  if (snapIcon) snapIcon.element.style.visibility = "hidden";
};

export const removeSnapIcon = function (isMeasurement = false) {
  const firstPoint = isMeasurement
    ? this.measurementAreas[this.measurementAreas.length - 1].points[0]
    : this.areas[this.areas.length - 1].points[0];
  const snapIcon = firstPoint.children[1];
  if (snapIcon) {
    firstPoint.remove(snapIcon);
    snapIcon.element.remove();
  }
};

export const changeCursorToCrosshair = function () {
  this.renderer.domElement.style.cursor = "crosshair";
};

export const changeCursorToPointer = function () {
  this.renderer.domElement.style.cursor = "pointer";
};

export const changeCursorToGrab = function () {
  this.renderer.domElement.style.cursor = "grab";
};

export const getNeighboringPoints = function (point, isMeasurement) {
  const array = isMeasurement ? this.measurementAreas : this.areas;

  let neighborPoints = [];
  for (let area of array) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) {
        if (area.points.length < 4) return [];

        if (i === 0) {
          neighborPoints.push(area.points[area.points.length - 1]);
        } else {
          neighborPoints.push(area.points[i - 1]);
        }
        if (i === area.points.length - 1) {
          neighborPoints.push(area.points[0]);
        } else {
          neighborPoints.push(area.points[i + 1]);
        }
        break;
      }
    }
  }
  return neighborPoints;
};

export const checkMergePoints = function (point, isMeasurement = true) {
  const neighborPoints = this.getNeighboringPoints(point, isMeasurement);
  const area = this.getAreaFromPoint(point);

  if (neighborPoints.length !== 2) return point;

  let returnedPoint = point;
  neighborPoints.forEach((neighborPoint) => {
    let distance;
    if (isMeasurement) {
      distance = point.position.distanceTo(neighborPoint.position);
    } else {
      const projectedPoint = new THREE.Vector3();
      const vectorPoint = new THREE.Vector3(
        point.position.x,
        point.position.y,
        point.position.z
      );
      area.trianglePlane.projectPoint(vectorPoint, projectedPoint);
      distance = projectedPoint.distanceTo(neighborPoint.position);
    }
    if (distance < 0.3) {
      neighborPoint.merge = true;
      returnedPoint = this.mergePoints(point, neighborPoints, isMeasurement);

      if (isMeasurement) {
        this.disableMeasurementPointDragMode();
        this.enableMeasurementPointDragMode();
      } else {
        this.disablePointDragMode();
        this.enablePointDragMode();
      }
    }
  });
  return returnedPoint;
};

export const mergePoints = function (
  pointToRemove,
  neighborPoints,
  isMeasurement
) {
  const array = isMeasurement ? this.measurementAreas : this.areas;

  let currentArea = null;
  for (let area of array) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === pointToRemove.uuid) {
        currentArea = area;
        break;
      }
    }
  }
  if (!currentArea) return;

  const storedPoints = [...currentArea.points];
  const storedLines = [...currentArea.lines];

  this.hideObjectFromScene(pointToRemove);
  const newPoints = currentArea.points.filter(
    (point) => point.uuid !== pointToRemove.uuid
  );
  currentArea.points = newPoints;

  const mergedPoint = neighborPoints.find((point) => point.merge);
  const otherPoint = neighborPoints.find((point) => !point.merge);

  const lineToRemove = currentArea.lines.find(
    (line) =>
      (line.firstPoint.uuid === pointToRemove.uuid ||
        line.secondPoint.uuid === pointToRemove.uuid) &&
      (line.firstPoint.uuid === mergedPoint.uuid ||
        line.secondPoint.uuid === mergedPoint.uuid)
  );

  const lineToUpdate = currentArea.lines.find(
    (line) =>
      (line.firstPoint.uuid === pointToRemove.uuid ||
        line.secondPoint.uuid === pointToRemove.uuid) &&
      (line.firstPoint.uuid === otherPoint.uuid ||
        line.secondPoint.uuid === otherPoint.uuid)
  );

  this.hideObjectFromScene(lineToRemove.line);
  if (isMeasurement) this.hideObjectFromScene(lineToRemove.label);
  this.hideObjectFromScene(lineToRemove.midPoint);

  currentArea.lines = currentArea.lines.filter(
    (line) => line.line.uuid !== lineToRemove.line.uuid
  );

  let firstPointRemoved = false;
  if (lineToUpdate.firstPoint.uuid === pointToRemove.uuid) {
    lineToUpdate.firstPoint = mergedPoint;
    firstPointRemoved = true;
  } else {
    lineToUpdate.secondPoint = mergedPoint;
  }

  this.resetMergePoints(neighborPoints);
  this.undoStack.push({
    action: "MERGE_POINT",
    area: currentArea,
    points: storedPoints,
    lines: storedLines,
    lineToUpdate,
    firstPointRemoved,
    isMeasurement,
  });

  return mergedPoint;
};

export const resetMergePoints = function (array) {
  for (let item of array) {
    item.merge = false;
  }
};

export const undoMergePoint = function (
  area,
  points,
  lines,
  lineToUpdate,
  firstPointRemoved,
  isMeasurement
) {
  const storedPoints = [...area.points];
  const storedLines = [...area.lines];

  const removedPoint = points.find(
    (point) => !storedPoints.map((p) => p.uuid).includes(point.uuid)
  );

  removedPoint.position.x = removedPoint.originalPosition.x;
  removedPoint.position.y = removedPoint.originalPosition.y;
  removedPoint.position.z = removedPoint.originalPosition.z;

  let mergedPoint;
  if (firstPointRemoved) {
    mergedPoint = lineToUpdate.firstPoint;
    lineToUpdate.firstPoint = removedPoint;
  } else {
    mergedPoint = lineToUpdate.secondPoint;
    lineToUpdate.secondPoint = removedPoint;
  }

  // add action to redo stack
  this.redoStack.push({
    action: "MERGE_POINT",
    area: area,
    points: storedPoints,
    lines: storedLines,
    lineToUpdate,
    firstPointRemoved,
    mergedPoint,
    isMeasurement,
  });

  area.points = points;
  area.lines = lines;

  // execute undo action
  for (let point of points) {
    const object = this.scene.getObjectById(point.id);
    object.visible = true;
  }
  for (let line of lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);

    lineObject.visible = true;
    midPointObject.visible = true;

    if (isMeasurement) {
      const labelObject = this.scene.getObjectById(line.label.id);
      labelObject.visible = true;
    }
  }

  if (isMeasurement) this.reDrawMeasurementAreaFromPoint(removedPoint);
  else this.reDrawAreaFromPoint(removedPoint);
};

export const redoMergePoint = function (
  area,
  points,
  lines,
  lineToUpdate,
  firstPointRemoved,
  mergedPoint,
  isMeasurement
) {
  const storedPoints = [...area.points];
  const storedLines = [...area.lines];

  // add action to undo stack
  this.undoStack.push({
    action: "MERGE_POINT",
    area: area,
    points: storedPoints,
    lines: storedLines,
    lineToUpdate,
    firstPointRemoved,
    isMeasurement,
  });

  const removedPoint = storedPoints.find(
    (point) => !points.map((p) => p.uuid).includes(point.uuid)
  );
  const removedLine = storedLines.find(
    (line) => !lines.map((l) => l.line.uuid).includes(line.line.uuid)
  );

  if (firstPointRemoved) {
    lineToUpdate.firstPoint = mergedPoint;
  } else {
    lineToUpdate.secondPoint = mergedPoint;
  }

  area.points = points;
  area.lines = lines;

  // execute redo action
  this.hideObjectFromScene(removedPoint);
  this.hideObjectFromScene(removedLine.line);
  this.hideObjectFromScene(removedLine.midPoint);
  if (isMeasurement) this.hideObjectFromScene(removedLine.label);

  if (isMeasurement) this.reDrawMeasurementAreaFromPoint(mergedPoint);
  else this.reDrawAreaFromPoint(mergedPoint);
};

export const pointBelongsToArea = function (point, area) {
  if (area.points.map((point) => point.uuid).includes(point.uuid)) return true;
  return false;
};
