import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import API from "@/api/API";
import Papa from "papaparse";

export const showCamera = function () {
  const loader = new GLTFLoader();
  loader.setPath("/assets/model/");
  loader.load("camera.glb", (gltf) => {
    this.cameraObject = gltf.scene;

    this.cameraObject.position.set(0, 0, 0);
    this.scene.add(gltf.scene);
    this.cameraObject = gltf.scene;
    const sceneCamera = this.scene.getObjectById(this.cameraObject.id);
    sceneCamera.visible = false;
  });
};

export const displayCameraPositionsFromCsvFile = async function () {
  const project = await API.projects.getProject(this.projectId);
  const cameraPositionsCsvUrl = project?.data?.fastFusionModel?.camPosUrl;
  const sceneCamera = this.scene.getObjectById(this.cameraObject.id);
  sceneCamera.visible = true;
  try {
    const response = await fetch(cameraPositionsCsvUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch the CSV file.");
    }
    let combinedSphere = new THREE.Sphere();
    if (this.modelObject) {
      let modelSphere = new THREE.Box3()
        .setFromObject(this.modelObject)
        .getBoundingSphere(new THREE.Sphere());
      combinedSphere.copy(modelSphere);
    }
    const csvText = await response.text();
    Papa.parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const cameraGroup = new THREE.Group();

        results.data.forEach((row) => {
          if (
            row.x &&
            row.y &&
            row.alt &&
            row.pitch &&
            row.heading &&
            row.roll
          ) {
            const cameraModel = this.cameraObject.clone(true);
            cameraModel.scale.set(5, 5, 5);
            cameraModel.position.set(row.x, row.y, row.alt);
            const pitchRadians = THREE.MathUtils.degToRad(row.pitch);
            const headingRadians = THREE.MathUtils.degToRad(row.heading);
            const rollRadians = THREE.MathUtils.degToRad(row.roll);
            cameraModel.rotation.set(
              pitchRadians,
              rollRadians - 5,
              -headingRadians,
              "ZXY"
            );

            cameraGroup.add(cameraModel);
          }
        });
        this.cameraGroup = cameraGroup;
        this.modelObject.add(cameraGroup);
        this.cameraControls.dollyTo(120, true);
      },
    });

    sceneCamera.visible = false;
  } catch (error) {
    console.error("Error fetching or parsing CSV:", error);
  }
};

export const displayCameras = function () {
  this.camerasDisplayed = true;
  if (this.cameraGroup) {
    this.changeCamerasVisibility(true);
  } else {
    this.displayCameraPositionsFromCsvFile();
  }
};

export const changeCamerasVisibility = function (visibility) {
  this.camerasDisplayed = visibility;
  const sceneCameras = this.scene.getObjectById(this.cameraGroup.id);
  sceneCameras.visible = visibility;

  if (visibility && this.cameraGroup) {
    this.cameraControls.dollyTo(120, true);
  }
};
