const VueHtmlToPaper = {
  install(app) {
    app.config.globalProperties.$htmlToPaper = (data, cb = () => true) => {
      var ifprint = document.createElement("iframe");
      document.body.appendChild(ifprint);
      ifprint.setAttribute("style", "height:0;width:0;");

      const win = ifprint.contentWindow;

      win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
            </head>
            <body>
              ${data}
            </body>
          </html>
        `);

      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
        cb();
      }, 1000);

      return true;
    };
  },
};

export default VueHtmlToPaper;
