import * as THREE from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { toRaw } from "vue";
import API from "@/api/API";
import { RESTRICTED_AREA_COLOR, RENDERING_ORDER } from "../constants";
import trashRed from "@/assets/model/trash_red.svg";

export const addRestrictedAreaPoint = async function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  // clicked outside
  if (!this.selectedArea) {
    this.toggleActive(2);
    return;
  }

  // clicked on a different solar group
  if (this.previousSolarArea.plane.id !== this.selectedArea.plane.id) {
    this.toggleActive(2);
    return;
  }

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.selectedArea.plane);

  if (intersects.length < 1) return;

  this.disablePointDragModeForRestrictedAreas();

  const restrictedAreas = this.selectedArea.restrictedAreas;

  // check for duplicate points
  if (
    restrictedAreas.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length < 2
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      restrictedAreas[restrictedAreas.length - 1].points
    );
    if (pointIntersects.length > 0) {
      if (restrictedAreas[restrictedAreas.length - 1].closed);
      return;
    }
  }
  if (
    restrictedAreas.length > 0 &&
    !restrictedAreas[restrictedAreas.length - 1].closed
  ) {
    const lastArea = restrictedAreas[restrictedAreas.length - 1];
    if (lastArea.tempLine) {
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      const solidLine = this.createReactiveThickLine(
        solidLinePoints,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );

      this.removeDashedLine();
      this.scene.add(solidLine);

      lastArea.tempLine = null;
      if (lastArea.lines) {
        lastArea.lines.push({
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
        });
      } else {
        lastArea.lines = [
          {
            line: solidLine,
            firstPoint: lastArea.firstPoint,
            secondPoint: this.checkForClosedArea(lastArea)
              ? lastArea.points[0]
              : lastArea.tempPoint,
          },
        ];
      }
    }
  }
  // check for closed area
  if (
    restrictedAreas.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length > 2 &&
    !restrictedAreas[restrictedAreas.length - 1].closed
  ) {
    let pointIntersects = this.raycaster.intersectObject(
      restrictedAreas[restrictedAreas.length - 1].points[0]
    );
    if (
      pointIntersects.length > 0 ||
      restrictedAreas[restrictedAreas.length - 1].closeArea
    ) {
      this.drawPlaneForRestrictedArea(
        restrictedAreas[restrictedAreas.length - 1].points
      );
      return;
    }
  }

  if (
    restrictedAreas.length > 0 &&
    restrictedAreas[restrictedAreas.length - 1].points.length > 0
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      restrictedAreas[restrictedAreas.length - 1].points
    );
    if (pointIntersects.length > 0) return;
  }

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(pIntersect, false, this.isFirstPoint);

  const tempPoint = this.createNonReactiveAreaPoint(
    pIntersect,
    RESTRICTED_AREA_COLOR
  );

  if (
    restrictedAreas.length > 0 &&
    !restrictedAreas[restrictedAreas.length - 1].closed
  ) {
    restrictedAreas[restrictedAreas.length - 1].points.push(dot);
  } else {
    restrictedAreas.push({ points: [dot], closed: false });
  }

  let restrictedArea = restrictedAreas[restrictedAreas.length - 1];

  restrictedArea.firstPoint = dot;
  restrictedArea.tempPoint = tempPoint;

  if (
    restrictedArea.lines &&
    restrictedArea.lines[restrictedArea.lines.length - 1].firstPoint
  ) {
    restrictedArea.lines[restrictedArea.lines.length - 1].secondPoint = dot;
  }

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  restrictedArea.firstPoint.getWorldPosition(firstPoint);
  restrictedArea.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(
    points,
    4.0,
    true,
    false,
    RESTRICTED_AREA_COLOR
  );

  this.dashedMeasurementLine = newLine;
  restrictedArea.tempLine = newLine;
  dot.setPointColor(RESTRICTED_AREA_COLOR);
  this.scene.add(dot);
};

export const updatePreliminaryPointPositionForRestrictedArea = function (
  event
) {
  let intersects = [];
  if (
    this.selectedArea &&
    this.selectedArea.restrictedAreas.length > 0 &&
    !this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ].closed &&
    !this.inMagenticField
  ) {
    this.setMousePosition(event);
    intersects = this.raycaster.intersectObject(this.selectedArea.plane);

    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const area =
      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ];

    let marker = area.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    area.firstPoint.getWorldPosition(firstPoint);
    area.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const numPoints = area.points.length;
    if (numPoints > 2) {
      const areaFirstDot = new THREE.Vector3();
      area.points[0].getWorldPosition(areaFirstDot);
      const endingPoints = [areaFirstDot, secondPoint];

      if (this.restrictedAreaEndingLine) {
        this.updateLinePosition(this.restrictedAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true,
          RESTRICTED_AREA_COLOR
        );
        this.restrictedAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  } else if (this.selectedArea) {
    this.setMousePosition(event);

    const solarObjects = [];

    this.selectedArea.restrictedAreas
      .filter((restrictedArea) => restrictedArea.closed)
      .forEach((restrictedArea) => {
        restrictedArea.points.forEach((point) => {
          solarObjects.push(point);
        });
      });

    intersects = this.raycaster.intersectObjects(solarObjects);

    if (intersects.length > 0) {
      this.mouseOverPoint = true;
    } else {
      this.mouseOverPoint = false;
    }
  }
  return intersects;
};

export const stickMousePointerToDotForRestrictedArea = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);
  if (
    this.selectedArea &&
    this.raycaster.intersectObject(this.selectedArea.plane).length > 0 &&
    !this.mouseOverPoint
  ) {
    this.changeCursorToCrosshair();
  }

  if (
    !this.selectedArea ||
    this.selectedArea.restrictedAreas.length === 0 ||
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ].points.length === 0 ||
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ].closed
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentArea =
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ];

  const firstDot = currentArea?.points[0];
  const secondDot = currentArea?.points[1];
  const thirdDot = currentArea?.points[2];
  const lastDot = currentArea?.points[currentArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ].closeArea = true;
      this.showSnapIcon();
      if (this.restrictedAreaEndingLine)
        this.restrictedAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      this.changeCursorToCrosshair();

      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ].closeArea = false;
      if (this.selectedPoint) {
        if (this.restrictedAreaEndingLine)
          this.restrictedAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();

        this.selectedPoint = null;
      }
    }
  } else {
    this.changeCursorToCrosshair();
    if (this.selectedPoint) {
      if (this.restrictedAreaEndingLine)
        this.restrictedAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.selectedPoint = null;
      this.hideSnapIcon();
    }
  }
};

export const drawPlaneForRestrictedArea = async function (
  points,
  createArea = true
) {
  this.resetUndoStack();
  this.resetRedoStack();
  this.removeSnapIcon();

  this.removeDashedLine();

  // redraw area outline
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const restrictedAreaEdges = [];
  for (let i = 0; i < vectorPoints.length; i++) {
    const nextIndex = (i + 1) % vectorPoints.length;
    restrictedAreaEdges.push([vectorPoints[i], vectorPoints[nextIndex]]);
  }

  if (this.restrictedAreaEndingLine)
    this.removeObjectFromScene(this.restrictedAreaEndingLine);

  this.restrictedAreaEndingLine = null;

  const projectedPoints = this.projectPointsOnPlane(
    points,
    this.selectedArea.trianglePlane
  );

  const area =
    this.selectedArea.restrictedAreas[
      this.selectedArea.restrictedAreas.length - 1
    ];
  this.projectLinesOnPlane(area.lines, this.selectedArea.trianglePlane);

  const geometry = new THREE.BufferGeometry();

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: RESTRICTED_AREA_COLOR,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.5,
  });

  const plane = new THREE.Mesh(geometry, material);
  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.SOLAR_PLANE;

  const trashCan = document.createElement("img");
  trashCan.src = trashRed;
  trashCan.style = "cursor:pointer; width: 25px; pointer-events: all;";

  trashCan.addEventListener("click", this.removeSelectedRestrictedArea);

  const trashCanDiv = document.createElement("div");
  trashCanDiv.style = "pointer-events: all; cursor: pointer;";
  trashCanDiv.append(trashCan);

  let label = new CSS2DObject(trashCanDiv);
  const centerPoint = this.getCenterPointFromVectors(vectorPoints);
  label.position.set(centerPoint.x, centerPoint.y, centerPoint.z);
  label.layers.set(0);
  label.renderOrder = RENDERING_ORDER.MEASUREMENT_AREA_LABEL;
  this.scene.add(label);

  area.plane = plane;
  area.label = label;
  area.closed = true;
  area.closeArea = false;
  area.indices = indices;
  area.edges = restrictedAreaEdges;

  const restrictedAreaIndex = this.selectedArea?.restrictedAreas?.length;
  this.selectedArea.restrictedAreas[restrictedAreaIndex - 1] = {
    ...this.selectedArea.restrictedAreas[restrictedAreaIndex - 1],
    index: restrictedAreaIndex - 1,
  };

  this.scene.add(plane);

  // Get the lines of the solar group
  const solarGroupLines = this.selectedArea.lines || [];
  const restrictedAreaLines = area.lines || [];

  // Function to check if two line segments intersect
  function checkLineIntersection(line1, line2) {
    const start1 = line1.firstPoint.position;
    const end1 = line1.secondPoint.position;
    const start2 = line2.firstPoint.position;
    const end2 = line2.secondPoint.position;

    const denominator =
      (end2.z - start2.z) * (end1.x - start1.x) -
      (end2.x - start2.x) * (end1.z - start1.z);
    if (denominator === 0) return false; // Lines are parallel

    const ua =
      ((end2.x - start2.x) * (start1.z - start2.z) -
        (end2.z - start2.z) * (start1.x - start2.x)) /
      denominator;
    const ub =
      ((end1.x - start1.x) * (start1.z - start2.z) -
        (end1.z - start1.z) * (start1.x - start2.x)) /
      denominator;

    if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
      const intersection = new THREE.Vector3(
        start1.x + ua * (end1.x - start1.x),
        start1.y + ua * (end1.y - start1.y),
        start1.z + ua * (end1.z - start1.z)
      );
      return intersection;
    }

    return false;
  }

  // Check for intersections between restricted area lines and solar group lines
  let intersectionFound = false;
  restrictedAreaLines.forEach((restrictedLine) => {
    solarGroupLines.forEach((solarLine) => {
      const intersection = checkLineIntersection(restrictedLine, solarLine);
      if (intersection) {
        // Handle the intersection as needed
        intersectionFound = true;
      }
    });
  });
  if (intersectionFound) {
    this.hideSnapIcon(true);
    const lastRestrictedArea = this.selectedArea.restrictedAreas.pop();
    this.removePoints(lastRestrictedArea.points);
    this.removeArrayFromScene(
      lastRestrictedArea.lines.map((line) => line.line)
    );
    if (lastRestrictedArea.plane) {
      this.removeObjectFromScene(lastRestrictedArea.plane);
    }
    if (lastRestrictedArea.label) {
      this.removeObjectFromScene(lastRestrictedArea.label);
    }
    return;
  }

  this.checkPanelsInSolarArea(this.selectedArea);

  this.hideSnapIcon(true);

  this.checkPanelNumberAndVisibility(this.selectedArea);

  this.enablePointDragModeForRestrictedAreas();

  this.hideAreaLabelIcons();

  if (!this.anonymousUser && createArea && !this.sample) {
    try {
      const position = [];

      this.selectedArea.points.forEach((element) => {
        const positionObject = {
          x: parseFloat(element.position.x.toFixed(3)),
          y: parseFloat(element.position.y.toFixed(3)),
          z: parseFloat(element.position.z.toFixed(3)),
        };
        position.push(positionObject);
      });

      const restrictedAreaPoints = [];

      if (this.selectedArea.restrictedAreas.length) {
        this.selectedArea.restrictedAreas.forEach((area) => {
          const areaPoints = area.points.map((point) => ({
            x: parseFloat(point.position.x.toFixed(3)),
            y: parseFloat(point.position.y.toFixed(3)),
            z: parseFloat(point.position.z.toFixed(3)),
          }));

          restrictedAreaPoints.push(areaPoints);
        });
      }

      const { data } = await this.createRestrictedAreaObject(
        restrictedAreaPoints,

        this.selectedArea
      );
      this.selectedArea.restrictedAreas[
        this.selectedArea.restrictedAreas.length - 1
      ].id = data;
    } catch (e) {
      console.log(e, "trh error");
    }
  }
};

export const createRestrictedAreaObject = async function (
  restrictedAreaPositions,
  area
) {
  if (this.sample) return;

  const data = {
    restricted_areas: restrictedAreaPositions,
  };

  try {
    // Perform API request
    const response = await API.airteam3DViewer.patchRestrictedAreaObject(
      data,
      area.id
    );

    // Ensure the response is as expected

    // Assuming response contains the data needed
    return response.data; // Modify based on actual response structure
  } catch (error) {
    console.error("Error occurred while updating solar group object:", error);
    throw error; // Rethrow the error to be caught by calling code
  }
};

export const removeUnfinishedRestrictedAreas = function () {
  const solarGroups = this.areas.filter(
    (area) =>
      area.type === "SOLAR_GROUP" &&
      area.restrictedAreas &&
      area.restrictedAreas.length > 0
  );

  for (let area of solarGroups) {
    const restrictedArea =
      area.restrictedAreas[area.restrictedAreas.length - 1];
    if (restrictedArea.closed) continue;

    this.removePoints(restrictedArea.points);

    if (this.restrictedAreaEndingLine) {
      this.removeObjectFromScene(this.restrictedAreaEndingLine);
      this.restrictedAreaEndingLine = null;
    }
    this.removeDashedLine();

    if (restrictedArea.lines) {
      this.removeArrayFromScene(restrictedArea.lines.map((line) => line.line));
    }

    area.restrictedAreas = area.restrictedAreas.slice(
      0,
      area.restrictedAreas.length - 1
    );
  }
};

export const showRestrictedAreaDetails = function (solarArea) {
  solarArea.restrictedAreas
    .filter((area) => area.closed)
    .forEach((area) => {
      const label = area.label;
      const secondColumn = label.element.children[0];
      const points = area?.points;
      const lines = area?.lines;
      secondColumn.style =
        "margin: auto; margin-left: 8px; width: 25px; height: 25px";

      if (points.length > 0) {
        points.forEach((point) => {
          point.visible = true;
        });
      }
      if (lines.length > 0) {
        lines.forEach((line) => {
          line.line.visible = true;
        });
      }
    });
};

export const hideRestrictedAreaDetails = function (solarGroup) {
  solarGroup.restrictedAreas
    .filter((area) => area.closed)
    .forEach((area) => {
      const label = area.label;
      const points = area.points;
      const lines = area.lines;
      const secondColumn = label?.element.children[0];
      if (secondColumn) {
        secondColumn.style = "margin: auto; margin-left: 8px; display: none;";
      }
      if (points && points.length > 0) {
        points.forEach((point) => {
          point.visible = false;
        });
      }
      if (lines && lines.length > 0) {
        lines.forEach((line) => {
          line.line.visible = false;
        });
      }
    });
};

// delete all restricted area inside solar group
export const removeAllRestrictedAreas = function (area) {
  // Loop through and remove all restricted areas
  area.restrictedAreas.forEach((area) => {
    this.hideObjectFromScene(area.plane);
    this.hideObjectFromScene(area.label);

    area.points.forEach((point) => this.hideObjectFromScene(point));
    area.lines.forEach((line) => {
      this.hideObjectFromScene(line.line);
      this.hideObjectFromScene(line.label);
    });
  });
};

export const detectRestrictedArea = function (event) {
  event.preventDefault();

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.selectedArea.restrictedAreas
      .filter((area) => area.plane)
      .map((area) => area.plane)
  );
  if (intersects.length < 1) return;

  let areaToExpand;

  if (intersects.length == 2) {
    let objectOne = intersects[0].object;
    let objectTwo = intersects[1].object;
    if (
      objectOne.geometry.boundingSphere.radius <
      objectTwo.geometry.boundingSphere.radius
    ) {
      areaToExpand = objectOne;
    } else {
      areaToExpand = objectTwo;
    }
  } else {
    areaToExpand = intersects[0].object;
  }

  const clickedArea = this.selectedArea.restrictedAreas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );

  return clickedArea;
};

export const removeRestrictedArea = function (area) {
  this.removeObjectFromScene(area.plane);
  this.removeObjectFromScene(area.label);

  for (let point of area.points) {
    this.removeObjectFromScene(point);
  }

  for (let line of area.lines) {
    this.removeObjectFromScene(line.line);
  }

  this.selectedArea.restrictedAreas = this.selectedArea.restrictedAreas.filter(
    (restrictedArea) => restrictedArea !== area
  );

  let restrictedAreaPoints = [];
  this.selectedArea.restrictedAreas.forEach((area) => {
    const areaPoints = area.points.map((point) => ({
      x: parseFloat(point.position.x.toFixed(3)),
      y: parseFloat(point.position.y.toFixed(3)),
      z: parseFloat(point.position.z.toFixed(3)),
    }));

    restrictedAreaPoints.push(areaPoints);
  });
  this.createRestrictedAreaObject(restrictedAreaPoints, this.selectedArea);
};

export const removeSelectedRestrictedArea = function (e) {
  const areaToDelete = this.detectRestrictedArea(e);

  if (!areaToDelete) return;

  let areaIndex;
  for (let i = 0; i < this.selectedArea.restrictedAreas.length; i++) {
    let areaObj = this.selectedArea.restrictedAreas[i];
    if (areaObj.plane.uuid === areaToDelete.plane.uuid) {
      areaIndex = i;
      break;
    }
  }

  this.removeObjectFromScene(areaToDelete.plane);
  this.removeObjectFromScene(areaToDelete.label);

  for (let point of areaToDelete.points) {
    this.removeObjectFromScene(point);
  }

  for (let line of areaToDelete.lines) {
    this.removeObjectFromScene(line.line);
  }

  this.selectedArea.restrictedAreas.splice(areaIndex, 1);

  const restrictedAreaPoints = [];

  if (this.selectedArea.restrictedAreas.length) {
    this.selectedArea.restrictedAreas.forEach((area) => {
      const areaPoints = area.points.map((point) => ({
        x: parseFloat(point.position.x.toFixed(3)),
        y: parseFloat(point.position.y.toFixed(3)),
        z: parseFloat(point.position.z.toFixed(3)),
      }));

      restrictedAreaPoints.push(areaPoints);
    });
  }
  this.checkPanelsInSolarArea(this.selectedArea);

  this.createRestrictedAreaObject(restrictedAreaPoints, this.selectedArea);
  this.checkPanelNumberAndVisibility(this.selectedArea);

  if (this.active === 6) this.hideAreaLabelIcons();
};

export const resetPointsColorForRestrictedAreas = function (area) {
  area?.restrictedAreas[area.restrictedAreas.length - 1]?.points.forEach(
    (point) => point.setPointColor(RESTRICTED_AREA_COLOR)
  );
};

export const enablePointDragModeForRestrictedAreas = function () {
  if (!this.selectedArea) return;

  for (let area of this.selectedArea.restrictedAreas) {
    if (area.dragControls) {
      area.dragControls.dispose();
    }
    area.dragControls = new DragControls(
      area.points,
      this.camera,
      this.renderer.domElement
    );

    area.dragControls.addEventListener(
      "drag",
      this.dragAreaPointForRestrictedArea
    );
    area.dragControls.addEventListener(
      "dragstart",
      this.dragStartedForRestrictedArea
    );
    area.dragControls.addEventListener(
      "dragend",
      this.dragEndedForRestrictedArea
    );
  }
};

export const disablePointDragModeForRestrictedAreas = function () {
  if (!this.selectedArea) return;

  for (let area of this.selectedArea.restrictedAreas) {
    if (area.dragControls) {
      area.dragControls.deactivate();
      area.dragControls.removeEventListener(
        "drag",
        this.dragAreaPointForRestrictedArea
      );
      area.dragControls.removeEventListener(
        "dragstart",
        this.dragStartedForRestrictedArea
      );
      area.dragControls.removeEventListener(
        "dragend",
        this.dragEndedForRestrictedArea
      );
    }
  }
};

export const dragAreaPointForRestrictedArea = function (event) {
  this.dragOn = true;
  const draggedPoint = event.object;
  this.reDrawRestrictedAreaFromPoint(draggedPoint);
  setTimeout(() => (this.dragOn = false));
};

export const reDrawRestrictedAreaFromPoint = function (point) {
  let selectedRestrictedArea = this.selectedRestrictedArea;

  if (!selectedRestrictedArea) {
    return;
  }

  for (let i = 0; i < selectedRestrictedArea.points.length; i++) {
    if (selectedRestrictedArea.points[i].uuid === point.uuid)
      selectedRestrictedArea.points[i] = point;
  }

  const vectorPoints = selectedRestrictedArea.points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const restrictedAreaEdges = [];
  for (let i = 0; i < vectorPoints.length; i++) {
    const nextIndex = (i + 1) % vectorPoints.length;
    restrictedAreaEdges.push([vectorPoints[i], vectorPoints[nextIndex]]);
  }
  selectedRestrictedArea.edges = restrictedAreaEdges;

  const projectedPoints = this.projectPointsOnPlane(
    selectedRestrictedArea.points,
    this.selectedArea.trianglePlane
  );

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    selectedRestrictedArea.points,
    this.getAxisDifferences(
      selectedRestrictedArea.points.map((point) => point.position)
    )
  );
  const indices = [].concat(...triangleIndices);

  selectedRestrictedArea.plane.geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
  );
  selectedRestrictedArea.plane.geometry.setIndex(
    new THREE.Uint16BufferAttribute(indices, 1)
  );
  selectedRestrictedArea.plane.geometry.computeBoundingBox();
  selectedRestrictedArea.plane.geometry.computeBoundingSphere();

  selectedRestrictedArea.indices = indices;

  const linesToUpdate = selectedRestrictedArea.lines.filter(
    (line) =>
      line.firstPoint.uuid === point.uuid ||
      line.secondPoint.uuid === point.uuid
  );

  for (let line of linesToUpdate) {
    if (line.firstPoint.uuid === point.uuid) line.firstPoint = point;
    else line.secondPoint = point;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];
    this.updateLinePosition(line.line, points);

    const cameraPosition = this.camera.position.clone();
    const pointPosition = point.position.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length > 0) {
      point.prevPosition = {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    }
  }

  return selectedRestrictedArea;
};

export const enableAddRestrictedAreaPoint = function () {
  document.addEventListener("click", this.addRestrictedAreaPoint, false);
};

export const disableAddRestrictedAreaPoint = function () {
  document.removeEventListener("click", this.addRestrictedAreaPoint, false);
};

export const dragStartedForRestrictedArea = function (e) {
  if (this.active === 6) this.disableAddRestrictedAreaPoint();

  const draggedPoint = e.object;
  draggedPoint.lastPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  draggedPoint.originalPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  this.selectedArea.moveGridLabel.visible = false;
  this.selectedArea.rotateGridLabel.visible = false;

  this.selectedRestrictedArea = null;
  for (let area of this.selectedArea.restrictedAreas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === draggedPoint.uuid) {
        this.selectedRestrictedArea = area;
        break;
      }
    }
  }
  this.selectedRestrictedArea.label.visible = false;

  // Deactivate drag controls for all other points
  this.selectedArea.restrictedAreas.forEach((area) => {
    if (
      area.plane.id !== this.selectedRestrictedArea.plane.id &&
      area.dragControls
    ) {
      area.dragControls.deactivate();
    }
  });

  this.renderer.domElement.style.cursor = "move"; // or 'grab', 'grabbing', etc.
};

export const dragEndedForRestrictedArea = function (e) {
  this.enablePointDragModeForRestrictedAreas();
  const draggedPoint = e.object;

  const cameraPosition = this.camera.position.clone();
  const pointPosition = draggedPoint.position.clone();
  const rayDirection = pointPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedPoint.position.x = draggedPoint.lastPosition.x;
    draggedPoint.position.y = draggedPoint.lastPosition.y;
    draggedPoint.position.z = draggedPoint.lastPosition.z;
    this.reDrawRestrictedAreaFromPoint(draggedPoint);
  }

  this.checkPanelsInSolarArea(this.selectedArea);

  const restrictedAreaPoints = [];

  if (this.selectedArea.restrictedAreas.length) {
    this.selectedArea.restrictedAreas.forEach((area) => {
      const areaPoints = area.points.map((point) => ({
        x: parseFloat(point.position.x.toFixed(3)),
        y: parseFloat(point.position.y.toFixed(3)),
        z: parseFloat(point.position.z.toFixed(3)),
      }));

      restrictedAreaPoints.push(areaPoints);
    });
  }
  this.selectedRestrictedArea.label.visible = true;

  // Calculate the center point of the restricted area
  const centerPoint = new THREE.Vector3();
  this.selectedRestrictedArea.points.forEach((point) => {
    centerPoint.add(point.position);
  });
  centerPoint.divideScalar(this.selectedRestrictedArea.points.length); // Average position

  // Update the trash icon position
  if (this.selectedRestrictedArea.label) {
    this.selectedRestrictedArea.label.position.copy(centerPoint);
  }

  this.createRestrictedAreaObject(restrictedAreaPoints, this.selectedArea);
  if (this.selectedArea.restrictedAreas) {
    this.selectedArea.restrictedAreas.forEach((restrictedArea) => {
      let outsideRA = [];

      if (
        !this.isRestrictedAreaContained(
          this.selectedArea.plane,
          restrictedArea.plane
        )
      ) {
        outsideRA.push(restrictedArea);
      }

      // Remove any restricted areas that are outside
      if (outsideRA.length > 0) {
        outsideRA.forEach((restrictedArea) => {
          this.removeRestrictedArea(restrictedArea);
        });
        this.checkPanelsInSolarArea(this.selectedArea);
      }
    });
    this.resetUndoStack();
    this.resetRedoStack();
  }

  this.resetRedoStack();

  this.checkPanelNumberAndVisibility(this.selectedArea);

  if (this.active === 6) {
    setTimeout(() => {
      this.enableAddRestrictedAreaPoint();
      this.hideAreaLabelIcons();
    }, 0);
  }
};

export const hideRestrictedAreas = function () {
  this.areas.forEach((area) => {
    this.hideRestrictedArea(area);
  });
};

export const hideRestrictedArea = function (area) {
  if (area.restrictedAreas) {
    this.hideRestrictedAreaDetails(area);
    area.restrictedAreas.forEach((restrictedArea) => {
      restrictedArea.plane.visible = false;
    });
  }
};
export const showRestrictedAreas = function () {
  this.areas.forEach((area) => {
    this.shoWRestrictedArea(area);
  });
};
export const shoWRestrictedArea = function (area) {
  if (area.restrictedAreas) {
    area.restrictedAreas.forEach((restrictedArea) => {
      restrictedArea.plane.visible = true;
    });
    if (area.inMagenticField) {
      this.checkPanelsInSolarArea(area);
    }
  }
};

export function processRestrictedAreas(solarGroup) {
  const areas = this.areas;
  const tempRestrictedAreas = this.areas[this.areas.length - 1].restrictedAreas;
  this.areas[this.areas.length - 1].restrictedAreas = [];

  if (tempRestrictedAreas) {
    tempRestrictedAreas.forEach((area) => {
      let points = [];
      this.displayRestrictedAreaPoint(area, solarGroup);
      for (let i = 0; i < area.length; i++) {
        let point = {
          position: {
            x: area[i].x,
            y: area[i].y,
            z: area[i].z,
          },
        };
        points.push(point);
      }
      this.drawPlaneForRestrictedArea(points, false);
      areas.forEach((area) => {
        this.resetPointsColorForRestrictedAreas(area);
      });
    });
  }
}

export const disableRestrictedAreaMode = function () {
  this.modes.find((mode) => mode.value === 6).disableFunction();
};

export const hideAreaLabelIcons = function () {
  if (this.selectedArea) {
    this.selectedArea.moveGridLabel.visible = false;
    this.selectedArea.rotateGridLabel.visible = false;
  }
};

export const showAreaLabelIcons = function () {
  if (this.selectedArea) {
    this.selectedArea.moveGridLabel.visible = true;
    this.selectedArea.rotateGridLabel.visible = true;
  }
};
