import { SOLAR_POINT_COLOR, MIDPOINT_COLOR } from "../constants";

export const undoAddPoint = function (
  area,
  point,
  line,
  midPoint,
  oldTempLine,
  oldFirstPoint,
  newTempLine,
  oldTempLabel,
  newTempLabel,
  fixedLine
) {
  // Add action to redo stack
  this.redoStack.push({
    action: "ADD_POINT",
    area: area,
    point: point,
    line: line,
    midPoint: midPoint,
    oldTempLine: oldTempLine,
    oldFirstPoint: oldFirstPoint,
    newTempLine: newTempLine,
    oldTempLabel: oldTempLabel,
    newTempLabel: newTempLabel,
    fixedLine: fixedLine,
  });
  // Find and remove the point from the areas
  const pointIndex = area.points.findIndex((p) => p.id === point.id);
  area.points.splice(pointIndex, 1);
  const lineIndex = area.lines.findIndex((l) => l.id === line.id);
  area.lines.splice(lineIndex, 1);

  // Hide the line, point, and midpoint objects
  this.hideObjectFromScene(point);
  this.hideObjectFromScene(line);
  this.hideObjectFromScene(midPoint);
  this.hideObjectFromScene(newTempLabel);
  this.removeDashedLine();

  area.firstPoint = oldFirstPoint;
  area.tempLine = oldTempLine;
  if (oldTempLabel) area.tempLabel = null;
  if (oldTempLabel) area.tempLabel = oldTempLabel;
  if (oldTempLabel) this.removeObjectFromScene(oldTempLabel);

  if (this.measurementAreaEndingLine) {
    this.removeObjectFromScene(this.measurementAreaEndingLine);
    this.measurementAreaEndingLine = null;
  }

  if (area.points.length === 0) {
    this.measurementAreas.splice(area, 1);
    this.resetRedoStack();
  }
};

export const redoAddPoint = function (
  area,
  point,
  line,
  midPoint,
  oldTempLine,
  oldFirstPoint,
  newTempLine,
  oldTempLabel,
  newTempLabel,
  fixedLine
) {
  // add action to undo stack
  this.undoStack.push({
    action: "ADD_POINT",
    area: area,
    point: point,
    line: line,
    midPoint: midPoint,
    oldTempLine: oldTempLine,
    oldFirstPoint: oldFirstPoint,
    newTempLine: newTempLine,
    oldTempLabel: oldTempLabel,
    newTempLabel: newTempLabel,
    fixedLine: fixedLine,
  });

  area.points.push(point);
  if (fixedLine) {
    this.updateLabelBetweenTwoPoints(
      fixedLine.label,
      fixedLine.firstPoint.position,
      fixedLine.secondPoint.position,
      0.5,
      true
    );
    area.lines.push(fixedLine);
  }

  if (line) line.visible = true;
  if (point) point.visible = true;
  if (midPoint) midPoint.visible = true;
  if (newTempLabel) newTempLabel.visible = true;

  this.removeDashedLine();

  area.firstPoint = point;
  area.tempLine = newTempLine;
  if (newTempLabel) area.tempLabel = newTempLabel;

  if (this.measurementAreaEndingLine) {
    this.removeObjectFromScene(this.measurementAreaEndingLine);
    this.measurementAreaEndingLine = null;
  }
};

export const undoMovePoint = function (areaType, area, point) {
  const object = this.scene.getObjectById(point.id);
  // add action to redo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.redoStack.push({
    action: "MOVE_POINT",
    areaType: areaType,
    area: area,
    point: point,
  });

  // execute undo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  switch (areaType) {
    case "MOUNTING":
      const selectedArea = this.reDrawAreaFromPoint(object);

      this.updateAreaObject(
        selectedArea.id,
        selectedArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    case "MEASUREMENT_AREA":
      const selectedMeasurementArea =
        this.reDrawMeasurementAreaFromPoint(object);

      this.updateMeasurementAreaObject(
        selectedMeasurementArea.id,
        selectedMeasurementArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    default:
      null;
  }
};

export const undoMoveMidPoint = function (
  area,
  removedLine,
  newLine1,
  newLine2,
  newPoint,
  isMeasurement = false
) {
  // Remove the newly created lines
  for (const newLine of [newLine1, newLine2]) {
    area.lines = area.lines.filter(
      (line) => line.line.uuid !== newLine.line.uuid
    );
    newLine.line.visible = false;
    newLine.midPoint.visible = false;
    if (isMeasurement) newLine.label.visible = false;
  }

  // Remove the newly created point
  this.hideObjectFromScene(newPoint);
  const pointIndex = area.points.findIndex((p) => p.id === newPoint.id);
  area.points.splice(pointIndex, 1);

  // Recreate removed line
  const midPoint = this.createReactiveMidPoint(
    removedLine.firstPoint,
    removedLine.secondPoint,
    isMeasurement ? MIDPOINT_COLOR : SOLAR_POINT_COLOR
  );
  this.scene.add(midPoint);

  removedLine.midPoint = midPoint;
  area.lines.push(removedLine);
  removedLine.line.visible = true;
  removedLine.midPoint.visible = true;
  if (isMeasurement) removedLine.label.visible = true;

  if (isMeasurement) {
    this.disableMeasurementPointDragMode();
    this.enableMeasurementPointDragMode();
  } else {
    this.disablePointDragMode();
    this.enablePointDragMode();
  }

  if (isMeasurement) {
    this.reDrawMeasurementAreaFromPoint(area.firstPoint);
    this.updateMeasurementAreaObject(
      area.id,
      area.points.map((point) => {
        return {
          x: point.position.x,
          y: point.position.y,
          z: point.position.z,
        };
      })
    );
  } else {
    this.reDrawAreaFromPoint(area.firstPoint);
    this.updateAreaObject(
      area.id,
      area.points.map((point) => {
        return {
          x: point.position.x,
          y: point.position.y,
          z: point.position.z,
        };
      })
    );
  }

  // Add to redo stack
  this.redoStack.push({
    action: "MOVE_MID_POINT",
    area: area,
    newLine: removedLine,
    newMidPoint: midPoint,
    removedLine1: newLine1,
    removedLine2: newLine2,
    removedPoint: newPoint,
    removedPointIndex: pointIndex,
    isMeasurement: isMeasurement,
  });
};

export const undoMoveMeasurementPoint = function (measurement, point) {
  const object = this.scene.getObjectById(point.id);
  // add action to redo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.redoStack.push({
    action: "MOVE_MEASUREMENT_POINT",
    measurement: measurement,
    point: point,
  });

  // execute undo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  this.reDraw(point);

  this.updateMeasurementObject(measurement.id, [
    {
      x: measurement.firstPoint.position.x,
      y: measurement.firstPoint.position.y,
      z: measurement.firstPoint.position.z,
    },
    {
      x: measurement.secondPoint.position.x,
      y: measurement.secondPoint.position.y,
      z: measurement.secondPoint.position.z,
    },
  ]);
};

export const redoMoveMidPoint = function (
  area,
  newLine,
  newMidPoint,
  removedLine1,
  removedLine2,
  removedPoint,
  removedPointIndex,
  isMeasurement
) {
  // Remove the newly created line
  area.lines = area.lines.filter(
    (line) => line.line.uuid !== newLine.line.uuid
  );
  newLine.line.visible = false;
  newMidPoint.visible = false;
  if (isMeasurement) newLine.label.visible = false;

  // Recreate the removed point
  removedPoint.visible = true;
  area.points.splice(removedPointIndex, 0, removedPoint);

  for (const removedLine of [removedLine1, removedLine2]) {
    area.lines.push(removedLine);
    removedLine.line.visible = true;
    removedLine.midPoint.visible = true;
    if (isMeasurement) removedLine.label.visible = true;
  }

  if (isMeasurement) {
    this.disableMeasurementPointDragMode();
    this.enableMeasurementPointDragMode();
    this.reDrawMeasurementAreaFromPoint(removedPoint);
    this.updateMeasurementAreaObject(
      area.id,
      area.points.map((point) => {
        return {
          x: point.position.x,
          y: point.position.y,
          z: point.position.z,
        };
      })
    );
  } else {
    this.disablePointDragMode();
    this.enablePointDragMode();
    this.reDrawAreaFromPoint(removedPoint);
    this.updateAreaObject(
      area.id,
      area.points.map((point) => {
        return {
          x: point.position.x,
          y: point.position.y,
          z: point.position.z,
        };
      })
    );
  }

  this.undoStack.push({
    action: "MOVE_MID_POINT",
    area: area,
    removedLine: newLine,
    newLine1: removedLine1,
    newLine2: removedLine2,
    newPoint: removedPoint,
    isMeasurement,
  });
};

export const redoMovePoint = function (areaType, area, point) {
  const object = this.scene.getObjectById(point.id);

  // add action to undo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.undoStack.push({
    action: "MOVE_POINT",
    areaType: areaType,
    area: area,
    point: point,
  });

  // execute redo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  switch (areaType) {
    case "MOUNTING":
      const selectedArea = this.reDrawAreaFromPoint(object);

      this.updateAreaObject(
        selectedArea.id,
        selectedArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    case "MEASUREMENT_AREA":
      const selectedMeasurementArea =
        this.reDrawMeasurementAreaFromPoint(object);

      this.updateMeasurementAreaObject(
        selectedMeasurementArea.id,
        selectedMeasurementArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    default:
      null;
  }
};

export const redoMoveMeasurementPoint = function (measurement, point) {
  const object = this.scene.getObjectById(point.id);
  // add action to redo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.undoStack.push({
    action: "MOVE_MEASUREMENT_POINT",
    measurement: measurement,
    point: point,
  });

  // execute undo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  this.reDraw(point);

  this.updateMeasurementObject(measurement.id, [
    {
      x: measurement.firstPoint.position.x,
      y: measurement.firstPoint.position.y,
      z: measurement.firstPoint.position.z,
    },
    {
      x: measurement.secondPoint.position.x,
      y: measurement.secondPoint.position.y,
      z: measurement.secondPoint.position.z,
    },
  ]);
};

export const undoDeleteArea = async function (area) {
  // add action to redo stack
  this.redoStack.push({ action: "DELETE_AREA", area: area });

  // execute undo action
  const areaObject = this.scene.getObjectById(area.plane.id);
  for (let panel of area.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.visible = true;
  }
  for (let point of area.points) {
    const pointObject = this.scene.getObjectById(point.id);
    pointObject.visible = true;
  }
  for (let line of area.lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);
    lineObject.visible = true;
    midPointObject.visible = true;
  }
  this.areas.push(area);
  areaObject.visible = true;
  if (!this.anonymousUser) {
    try {
      const { data } = await this.createAreaObject(
        area.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      area.id = data;
    } catch (e) {}
  }
};

export const undoDeleteMeasurementArea = async function (area) {
  // add action to redo stack
  this.redoStack.push({ action: "DELETE_MEASUREMENT_AREA", area: area });

  // execute undo action
  const areaObject = this.scene.getObjectById(area.plane.id);
  const measurementLabelObject = this.scene.getObjectById(area.label.id);

  for (let panel of area.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.visible = true;
  }
  for (let point of area.points) {
    const pointObject = this.scene.getObjectById(point.id);
    pointObject.visible = true;
  }
  for (let line of area.lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);
    const labelObject = this.scene.getObjectById(line.label.id);
    lineObject.visible = true;
    midPointObject.visible = true;
    labelObject.visible = true;
  }

  this.measurementAreas.push(area);

  areaObject.visible = true;
  measurementLabelObject.visible = true;

  this.unselectMeasurementArea(area.plane);

  if (!this.anonymousUser) {
    try {
      const { data } = await this.createMeasurementAreaObject(
        area.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      area.id = data;
    } catch (e) {}
  }
};

export const undoDeleteMeasurement = async function (measurement) {
  // add action to redo stack
  this.redoStack.push({
    action: "DELETE_MEASUREMENT",
    measurement: measurement,
  });

  measurement.line.visible = true;
  measurement.label.visible = true;
  measurement.firstPoint.visible = true;
  measurement.secondPoint.visible = true;
  measurement.xAxisLine.visible = true;
  measurement.yAxisLine.visible = true;
  measurement.xAxisLabel.visible = true;
  measurement.yAxisLabel.visible = true;

  this.measurements.push(measurement);

  if (!this.anonymousUser) {
    try {
      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();

      measurement.firstPoint.getWorldPosition(firstPoint);
      measurement.secondPoint.getWorldPosition(secondPoint);

      let points = [firstPoint, secondPoint];
      const { data } = await this.createMeasurementObject(points);
      measurement.id = data;
    } catch (e) {}
  }
};

export const redoDeleteArea = function (area) {
  // add action to undo stack
  this.undoStack.push({ action: "DELETE_AREA", area: area });

  const panelPromises = [];

  // execute redo action
  const areaObject = this.scene.getObjectById(area.plane.id);
  for (let panel of area.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelPromises.push(this.deletePanelObject(panel.id));

    panelObject.visible = false;
  }
  for (let point of area.points) {
    this.hideObjectFromScene(point);
  }
  for (let line of area.lines) {
    this.hideObjectFromScene(line.line);
    this.hideObjectFromScene(line.midPoint);
  }
  this.areas = this.areas.filter((area) => area.plane.id !== area.plane.id);
  areaObject.visible = false;

  Promise.all(panelPromises);
  this.deleteAreaObject(area.id);
};

export const redoDeleteMeasurementArea = function (area) {
  // add action to undo stack
  this.undoStack.push({ action: "DELETE_MEASUREMENT_AREA", area: area });

  // execute redo action
  const areaObject = this.scene.getObjectById(area.plane.id);

  this.hideObjectFromScene(area.label);

  for (let point of area.points) {
    this.hideObjectFromScene(point);
  }
  for (let line of area.lines) {
    this.hideObjectFromScene(line.line);
    this.hideObjectFromScene(line.midPoint);
    this.hideObjectFromScene(line.label);
  }
  this.measurementAreas = this.measurementAreas.filter(
    (area) => area.plane.id !== area.plane.id
  );
  areaObject.visible = false;

  this.deleteMeasurementAreaObject(area.id);
};

export const redoDeleteMeasurement = async function (measurement) {
  // add action to undo stack
  this.undoStack.push({
    action: "DELETE_MEASUREMENT",
    measurement: measurement,
  });

  this.hideObjectFromScene(measurement.line);
  this.hideObjectFromScene(measurement.label);
  this.hideObjectFromScene(measurement.firstPoint);
  this.hideObjectFromScene(measurement.secondPoint);
  this.hideObjectFromScene(measurement.xAxisLine);
  this.hideObjectFromScene(measurement.yAxisLine);
  this.hideObjectFromScene(measurement.xAxisLabel);
  this.hideObjectFromScene(measurement.yAxisLabel);

  this.measurements = this.measurements.filter(
    (m) => m.line.id !== measurement.line.id
  );

  this.deleteMeasurementObject(measurement.id);
};
