import * as THREE from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import API from "@/api/API";
import {
  BLACK,
  VERTICAL,
  HORIZONTAL,
  RENDERING_ORDER,
  MOUNTING_SURFACE_COLOR,
  TEST_COMPANIES,
} from "../constants";
import { isPanelSmall } from "@/utils/panel-size.js";
import { roundVector } from "@/utils/round-vector.js";
import { InstancedMesh2 } from "@three.ez/instanced-mesh";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import * as turf from "@turf/turf";
import { event } from "vue-gtag";
import { isProduction, isStaging } from "@/utils/env";
import { v4 as uuidv4 } from "uuid";

export const cleanPanelSetup = function () {
  this.panelAdditionType = null;
  this.panelType = null;
  this.panelStep = 0;
  this.panelSpacing = null;
  this.panelSetupConfirmed = false;
};

export const getPanelArea = function (area) {
  let totalArea = 0;
  for (let panel of area.panels) {
    totalArea += panel.size.width * panel.size.height;
  }
  return totalArea.toFixed(2);
};

export const removePanels = async function (panelsToRemove, area) {
  const panelPromises = [];
  for (let panelToRemove of panelsToRemove) {
    const panelObject = this.scene.getObjectById(panelToRemove.plane.id);
    area.panels = area.panels.filter(
      (panel) => panel.plane.id !== panelToRemove.plane.id
    );
    panelObject.visible = false;

    panelPromises.push(panelToRemove.id);
  }

  const panelArrays = chunkArray(panelPromises, 50);
  const panelArrayPromises = panelArrays.map((array) =>
    this.deleteBulkPanelObjects(array)
  );
  Promise.all(panelArrayPromises);

  this.undoStack.push({
    action: "DELETE_PANELS",
    panels: panelsToRemove,
    area: this.selectedArea,
  });
  this.resetRedoStack();
};

export const hidePanels = async function (panelsToRemove, area) {
  const panelPromises = [];
  for (let panelToRemove of panelsToRemove) {
    const panelObject = this.scene.getObjectById(panelToRemove.plane.id);
    area.panels = area.panels.filter(
      (panel) => panel.plane.id !== panelToRemove.plane.id
    );
    panelObject.visible = false;
    panelPromises.push(this.deletePanelObject(panelToRemove.id));
  }

  Promise.all(panelPromises);
};

export const deleteSelectedPanel = function (event) {
  const key = event.key;
  if (key === "Delete" || key === "Backspace") {
    const selectedPanels = this.selectedArea.panels.filter(
      (panel) => panel.selected
    );
    if (selectedPanels.length > 0)
      this.removePanels(selectedPanels, this.selectedArea);
  }
};

export const projectVectorOntoPlane = function (vector, normal) {
  return vector.clone().sub(normal.clone().multiplyScalar(vector.dot(normal)));
};

export const getCameraVector = function (vector) {
  vector.applyQuaternion(this.camera.quaternion);
  return vector;
};

export const findIntersectionBetweenLines = function (
  line1Start,
  line1End,
  line2Start,
  line2End
) {
  const line1Direction = new THREE.Vector3()
    .subVectors(line1End, line1Start)
    .normalize();
  const line2Direction = new THREE.Vector3()
    .subVectors(line2End, line2Start)
    .normalize();

  const crossProduct = new THREE.Vector3().crossVectors(
    line1Direction,
    line2Direction
  );

  if (crossProduct.lengthSq() === 0) {
    // Lines are parallel, so they do not intersect
    return null;
  }

  const diff = new THREE.Vector3().subVectors(line2Start, line1Start);
  const determinant = crossProduct.lengthSq();

  const t1 = diff.clone().cross(line2Direction).dot(crossProduct) / determinant;
  const t2 = diff.clone().cross(line1Direction).dot(crossProduct) / determinant;

  const pointOnLine1 = line1Start
    .clone()
    .add(line1Direction.clone().multiplyScalar(t1));
  const pointOnLine2 = line2Start
    .clone()
    .add(line2Direction.clone().multiplyScalar(t2));

  // Check if the intersection points on both lines are the same (i.e., lines truly intersect)
  if (pointOnLine1.distanceTo(pointOnLine2) < 1e-6) {
    return pointOnLine1;
  }

  return pointOnLine1;
};

export const calculateProjectedPoint = function (
  xPoint,
  yPoint,
  xDirection,
  yDirection,
  xPositive,
  yPositive
) {
  const xLineEnd = xPoint
    .clone()
    .add(xDirection.multiplyScalar(xPositive ? 50 : -50));
  const yLineEnd = yPoint
    .clone()
    .add(yDirection.multiplyScalar(yPositive ? 50 : -50));

  const intersectionPoint = this.findIntersectionBetweenLines(
    xPoint,
    xLineEnd,
    yPoint,
    yLineEnd
  );

  return intersectionPoint;
};

export const getSimulatedCamera = function (center, normal) {
  const simulatedCamera = this.camera.clone();
  const cameraPosition = center.clone().add(normal.clone().multiplyScalar(15));
  simulatedCamera.position.copy(cameraPosition);
  simulatedCamera.lookAt(center);
  simulatedCamera.updateMatrixWorld();

  return simulatedCamera;
};

export const getAxisDirectionInPlane = function (
  simulatedCamera,
  axis,
  normal
) {
  const simulatedCameraVector = axis
    .clone()
    .applyQuaternion(simulatedCamera.quaternion.clone());

  const axisDirectionInPlane = projectVectorOntoPlane(
    simulatedCameraVector,
    normal
  ).normalize();

  return axisDirectionInPlane;
};

export const calculatePanelPlacement = function ({
  startPos,
  columns,
  rows,
  verticalVector,
  horizontalVector,
  verticalScalar,
  horizontalScalar,
  normal,
  ground,
  offset,
  panel,
  areaPoints,
  areaEdges,
  simulatedCamera,
}) {
  const placementId = uuidv4();

  const grid = [];
  let visiblePanels = 0;

  for (let i = -2; i <= rows + 1; i++) {
    const verticalOffset = verticalVector
      .clone()
      .multiplyScalar(verticalScalar * i);

    for (let j = -2; j <= columns + 1; j++) {
      const horizontalOffset = horizontalVector
        .clone()
        .multiplyScalar(horizontalScalar * j);

      let pos = startPos.clone().sub(verticalOffset).add(horizontalOffset);

      const { panelVertices, panelEdges } = this.createPanelAt({
        panel,
        pos,
        ground,
        normal,
      });

      if (
        this.isRectangleWithinPoints(
          panelVertices,
          panelEdges,
          areaPoints,
          areaEdges,
          simulatedCamera
        )
      ) {
        visiblePanels++;
      }
      pos = pos.sub(offset);
      grid.push(pos);
    }
  }

  return { id: placementId, count: visiblePanels, grid };
};

export const calculatePanelPlacementFromCenter = function ({
  startPos,
  stepX,
  stepY,
  normal,
  ground,
  offset,
  panel,
  areaPoints,
  areaEdges,
  simulatedCamera,
}) {
  const placementId = uuidv4();

  let addedPanels = [];

  let pos = startPos.clone();
  const { panelVertices, panelEdges } = this.createPanelAt({
    panel,
    pos,
    ground,
    normal,
  });

  if (
    this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      areaPoints,
      areaEdges,
      simulatedCamera
    )
  ) {
    pos = pos.sub(offset);
    addedPanels.push(pos);

    const zeroVector = new THREE.Vector3(0, 0, 0);
    this.expandInDirection({
      startPos,
      stepX,
      xPositive: true,
      stepY: zeroVector,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
    this.expandInDirection({
      startPos,
      stepX: stepX,
      xPositive: false,
      stepY: zeroVector,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
    this.expandInDirection({
      startPos,
      stepX: zeroVector,
      stepY,
      yPositive: true,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
    this.expandInDirection({
      startPos,
      stepX: zeroVector,
      stepY: stepY,
      yPositive: false,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
  }

  return { id: placementId, grid: addedPanels, count: addedPanels.length };
};

export const createPanelAt = function ({
  panel,
  pos,
  rotation,
  ground,
  normal,
}) {
  panel.position.copy(pos);
  panel.up.copy(ground);
  panel.lookAt(pos.clone().add(normal));

  if (rotation) {
    panel.setRotationFromQuaternion(rotation);
  }

  const geometry = panel.geometry.clone();
  panel.updateMatrix();
  panel.updateWorldMatrix();
  geometry.applyMatrix4(panel.matrixWorld);

  const flatPanelVertices = geometry.attributes.position.array;
  const panelVertices = this.flatArrayToVectors3D(flatPanelVertices);

  const indices = geometry.index.array;
  const panelEdges = [];
  for (let i = 0; i < indices.length; i++) {
    const edge = [
      panelVertices[indices[i]],
      panelVertices[indices[(i + 1) % indices.length]],
    ];
    panelEdges.push(edge);
  }

  return { panelVertices: panelVertices, panelEdges: panelEdges };
};

export const expandInDirection = function ({
  startPos,
  stepX,
  xPositive,
  stepY,
  yPositive,
  panel,
  ground,
  normal,
  areaPoints,
  areaEdges,
  simulatedCamera,
  offset,
  addedPanels,
}) {
  let pos = startPos.clone();

  if (yPositive) pos = pos.add(stepY);
  else pos = pos.sub(stepY);

  if (xPositive) pos = pos.add(stepX);
  else pos = pos.sub(stepX);

  const { panelVertices, panelEdges } = this.createPanelAt({
    panel,
    pos,
    ground,
    normal,
  });

  if (
    this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      areaPoints,
      areaEdges,
      simulatedCamera
    )
  ) {
    addedPanels.push(pos.clone().sub(offset));

    this.expandInDirection({
      startPos: pos,
      stepX,
      xPositive,
      stepY,
      yPositive,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
  }
};

export const normalTowardsCamera = function (position, normal) {
  const normalCopy = normal.clone();
  const toCamera = new THREE.Vector3()
    .subVectors(this.camera.position, position)
    .normalize();
  if (normalCopy.dot(toCamera) > 0) {
    normalCopy.negate();
  }
  return normalCopy;
};

export const differenceWithinRange = function (position_1, position_2) {
  const { x, y, z } = position_1;

  const xDifference = Math.abs(x - position_2.x);
  const yDifference = Math.abs(y - position_2.y);
  const zDifference = Math.abs(z - position_2.z);
  if (xDifference < 0.1 && yDifference < 0.1 && zDifference < 0.1) {
    return true;
  }
};

export const normalNeedsFlipping = function (position, normal) {
  const rayDirection = normal.clone().normalize();
  this.raycaster.set(position, rayDirection);
  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length === 0) return true;
  return false;
};

export const populateArea = async function (
  area,
  persist = true,
  forceFlipNormal = false,
  areaDragged = false
) {
  const vectorPoints = area.points.map((point) => point.position);
  const isAreaInFrontOfModel = this.isAreaInFrontOfModel(areaDragged);

  let normal = this.calculatePlaneNormal(vectorPoints);
  if (forceFlipNormal || !isAreaInFrontOfModel) normal.negate();

  const center = this.getCenterPointFromVectors(vectorPoints);
  const simulatedCamera = this.getSimulatedCamera(center, normal);

  const points = this.getInnerPlanePoints(area, normal, simulatedCamera);

  const innerPlane = this.createInnerPlane(points, area.stencilCount);
  area.innerPlane = innerPlane;
  this.scene.add(innerPlane);

  const innerPlaneEdges = [];
  for (let i = 0; i < points.length; i++) {
    const nextIndex = (i + 1) % points.length;
    innerPlaneEdges.push([points[i], points[nextIndex]]);
  }
  innerPlane.edges = innerPlaneEdges;

  const chosenPanel = this.getPanelById(area.panelId, area.orientation);
  const panelTexture = chosenPanel.texture;
  const panelWidth = chosenPanel.size.width;
  const panelHeight = chosenPanel.size.height;
  const panelSpacingX = area.horizontalSpacing / 100;
  const panelSpacingY = area.verticalSpacing / 100;

  const rightMostPoint = this.getRightMostPoint(points, simulatedCamera);
  const leftMostPoint = this.getLeftMostPoint(points, simulatedCamera);
  const topMostPoint = this.getTopMostPoint(points, simulatedCamera);
  const bottomMostPoint = this.getBottomMostPoint(points, simulatedCamera);

  const areaWidth = leftMostPoint.distanceTo(rightMostPoint);
  const areaHeight = bottomMostPoint.distanceTo(topMostPoint);
  const columns = Math.floor(areaWidth / (panelWidth + panelSpacingX));
  const rows = Math.floor(areaHeight / (panelHeight + panelSpacingY));

  const xAxis = new THREE.Vector3(1, 0, 0);
  const xDirectionInPlane = this.getAxisDirectionInPlane(
    simulatedCamera,
    xAxis,
    normal
  );

  const yAxis = new THREE.Vector3(0, 1, 0);
  const yDirectionInPlane = this.getAxisDirectionInPlane(
    simulatedCamera,
    yAxis,
    normal
  );

  const projectedTopLeftPoint = this.calculateProjectedPoint(
    topMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    true
  );

  const projectedTopRightPoint = this.calculateProjectedPoint(
    topMostPoint,
    rightMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    true,
    true
  );
  const projectedBottomLeftPoint = this.calculateProjectedPoint(
    bottomMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    false
  );

  const horizontalVector = projectedTopRightPoint
    .clone()
    .sub(projectedTopLeftPoint)
    .normalize();

  const verticalVector = projectedTopLeftPoint
    .clone()
    .sub(projectedBottomLeftPoint)
    .normalize();

  let startPos = projectedTopLeftPoint.clone();

  const verticalScalar = panelHeight + panelSpacingY;
  const horizontalScalar = panelWidth + panelSpacingX;

  const testPanel = this.createTempPanel(chosenPanel);

  const offset = normal.clone().multiplyScalar(area.offset / 100);

  const placementIterations = [
    roundVector(startPos),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
    ),
    roundVector(
      startPos
        .clone()
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
  ];

  let optimalPlacement = [];
  if (persist) {
    const placementIterationsResults = placementIterations.map((startPos) => {
      return this.calculatePanelPlacement({
        startPos,
        rows,
        columns,
        verticalVector,
        horizontalVector,
        verticalScalar,
        horizontalScalar,
        normal,
        ground: verticalVector,
        offset,
        panel: testPanel,
        areaPoints: points,
        areaEdges: innerPlaneEdges,
        simulatedCamera,
      });
    });

    const stepX = horizontalVector.clone().multiplyScalar(horizontalScalar);
    const stepY = verticalVector.clone().multiplyScalar(verticalScalar);
    const centerPlacementResult = this.calculatePanelPlacementFromCenter({
      startPos: center,
      stepX,
      stepY,
      normal,
      ground: verticalVector,
      offset,
      panel: testPanel,
      areaPoints: points,
      areaEdges: innerPlaneEdges,
      areaIndices: area.indices,
      simulatedCamera,
    });
    placementIterationsResults.push(centerPlacementResult);
    placementIterations.push(center);

    optimalPlacement = placementIterationsResults.reduce((prev, current) =>
      prev.count > current.count ? prev : current
    );

    const optimalPlacementIndex = placementIterationsResults.findIndex(
      (placement) => placement.id === optimalPlacement.id
    );
    if (optimalPlacement.count > 0) {
      area.startPosition = placementIterations[optimalPlacementIndex];
      area.originalPosition = placementIterations[optimalPlacementIndex];
      area.iteration = this.getPlacementTitle(optimalPlacementIndex);

      if (
        (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
        isStaging
      ) {
        event("panel_placement", {
          project_id: this.projectId,
          placement_index: optimalPlacementIndex,
          placement_title: area.iteration,
          number_of_points: points.length,
          number_of_panels: optimalPlacement.length,
          panel_width: chosenPanel.size.width,
          panel_height: chosenPanel.size.height,
          panel_orientation: area.orientation,
          horizontal_spacing: area.horizontalSpacing,
          vertical_spacing: area.verticalSpacing,
          margin: area.margin,
        });
      }
    }
  } else {
    placementIterations.push(center);

    if (this.normalNeedsFlipping(center, normal)) {
      if (!forceFlipNormal) {
        this.scene.remove(innerPlane);
        this.populateArea(area, false, true);
        return;
      }
    }
    if (this.differenceWithinRange(area.originalPosition, center)) {
      const stepX = horizontalVector.clone().multiplyScalar(horizontalScalar);
      const stepY = verticalVector.clone().multiplyScalar(verticalScalar);
      optimalPlacement = this.calculatePanelPlacementFromCenter({
        startPos: area.startPosition,
        stepX,
        stepY,
        normal,
        ground: verticalVector,
        offset,
        panel: testPanel,
        areaPoints: points,
        areaEdges: innerPlaneEdges,
        areaIndices: area.indices,
        simulatedCamera,
      });
    } else {
      optimalPlacement = this.calculatePanelPlacement({
        startPos: area.startPosition,
        rows,
        columns,
        verticalVector,
        horizontalVector,
        verticalScalar,
        horizontalScalar,
        normal,
        ground: verticalVector,
        offset,
        panel: testPanel,
        areaPoints: points,
        areaEdges: innerPlaneEdges,
        areaIndices: area.indices,
        simulatedCamera,
      });
    }
  }

  let instancedMesh;
  let label, rotateLabel;

  if (optimalPlacement.count > 0) {
    instancedMesh = this.createPanelInstance(
      panelWidth,
      panelHeight,
      panelTexture,
      optimalPlacement.grid.length
    );

    this.scene.add(instancedMesh);

    if (!area.moveGridLabel) {
      const moveGridElement = createGridElement();
      moveGridElement.src = "/assets/icons/move-grid.svg";

      const rotateGridElement = createGridElement();
      rotateGridElement.src = "/assets/icons/rotate-grid.svg";

      const centerPoint = this.getCenterPointFromVectors(points);

      const moveLabelOffset = horizontalVector.clone().multiplyScalar(-0.4);
      const moveLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(moveLabelOffset);

      label = this.createGridLabel(moveGridElement, moveLabelPosition);
      this.scene.add(label);
      area.moveGridLabel = label;
      label.labelOffset = moveLabelOffset;

      const rotateLabelOffset = horizontalVector.clone().multiplyScalar(0.4);
      const rotateLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(rotateLabelOffset);

      rotateLabel = this.createGridLabel(
        rotateGridElement,
        rotateLabelPosition
      );
      this.scene.add(rotateLabel);
      rotateLabel.labelOffset = rotateLabelOffset;
      area.rotateGridLabel = rotateLabel;

      moveGridElement.addEventListener("mousedown", this.onDragSolarGroupStart);

      rotateGridElement.addEventListener(
        "mousedown",
        this.onRotateSolarGroupStart
      );
    } else {
      const centerPoint = this.getCenterPointFromVectors(points);

      const label = area.moveGridLabel;
      label.labelOffset = horizontalVector.clone().multiplyScalar(-0.4);
      const moveLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(label.labelOffset);
      label.position.set(
        moveLabelPosition.x,
        moveLabelPosition.y,
        moveLabelPosition.z
      );

      const rotateLabel = area.rotateGridLabel;
      rotateLabel.labelOffset = horizontalVector.clone().multiplyScalar(0.4);
      const rotateLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(rotateLabel.labelOffset);
      rotateLabel.position.set(
        rotateLabelPosition.x,
        rotateLabelPosition.y,
        rotateLabelPosition.z
      );
    }
  }

  const infinitePlane = new THREE.Plane();
  infinitePlane.setFromNormalAndCoplanarPoint(normal, center);

  area.instancedMesh = instancedMesh;
  area.normal = normal;
  area.ground = verticalVector;
  area.simulatedCamera = simulatedCamera;
  area.testPanel = testPanel;
  area.verticalVector = verticalVector;
  area.horizontalVector = horizontalVector;
  area.infinitePlane = infinitePlane;

  const tempMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempTarget = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  if (instancedMesh) {
    for (let i = 0; i < optimalPlacement.grid.length; i++) {
      const position = optimalPlacement.grid[i];

      tempPosition.set(position.x, position.y, position.z);
      tempTarget.copy(tempPosition).add(normal);

      const tempMatrixLookAt = new THREE.Matrix4().lookAt(
        tempPosition,
        tempTarget,
        verticalVector
      );

      tempQuaternion.setFromRotationMatrix(tempMatrixLookAt);

      tempMatrix.compose(tempPosition, tempQuaternion, tempScale);

      instancedMesh.setMatrixAt(i, tempMatrix);
    }

    let seedIndex;
    if (area.iteration === "CENTER") {
      seedIndex = 0;
    } else {
      seedIndex = 2 * (columns + 4) + 2;
    }

    instancedMesh.getMatrixAt(seedIndex, tempMatrix);
    tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);
    instancedMesh.seed = tempPosition.clone();

    if (area.currentRotation) {
      area.areaCenter = center;

      for (let i = 0; i < optimalPlacement.grid.length; i++) {
        instancedMesh.getMatrixAt(i, tempMatrix);
        tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);

        tempPosition.sub(area.areaCenter);

        const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
          normal,
          area.currentRotation
        );
        tempPosition.applyMatrix4(rotationMatrix);
        tempQuaternion.premultiply(
          new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
        );

        tempPosition.add(area.areaCenter);

        tempMatrix.compose(tempPosition, tempQuaternion, tempScale);
        instancedMesh.setMatrixAt(i, tempMatrix);
      }
    }

    instancedMesh.instanceMatrix.needsUpdate = true;

    this.checkPanelsInSolarArea(area);
  }

  if (persist) {
    this.createUpdateSolarGroup(area);
    this.disableDefaultNavigation();
  }
};

export const removeSolarGroupPanels = function (area) {
  if (area.instancedMesh) {
    const instancedMesh = this.scene.getObjectById(area.instancedMesh.id);
    this.scene.remove(instancedMesh);
    instancedMesh.dispose();
    area.instancedMesh = null;
  }

  this.removeSolarGroupInnerPlane(area);
};

export const removeSolarGroupInnerPlane = function (area) {
  const innerPlane = this.scene.getObjectById(area.innerPlane.id);
  this.scene.remove(innerPlane);
  if (innerPlane) {
    innerPlane.geometry.dispose();
    innerPlane.material.dispose();
  }
  area.innerPlane = null;
};

export const replaceSolarGroupInnerPlane = function (area) {
  this.removeSolarGroupInnerPlane(area);

  const vectorPoints = area.points.map((point) => point.position);
  let normal = this.calculatePlaneNormal(vectorPoints);

  const center = this.getCenterPointFromVectors(vectorPoints);
  const simulatedCamera = this.getSimulatedCamera(center, normal);

  const points = this.getInnerPlanePoints(area, normal, simulatedCamera);

  const innerPlane = this.createInnerPlane(points, area.stencilCount);
  area.innerPlane = innerPlane;
  this.scene.add(innerPlane);

  const innerPlaneEdges = [];
  for (let i = 0; i < points.length; i++) {
    const nextIndex = (i + 1) % points.length;
    innerPlaneEdges.push([points[i], points[nextIndex]]);
  }
  innerPlane.edges = innerPlaneEdges;

  area.simulatedCamera = simulatedCamera;
  area.normal = normal;
  area.center = center;
};

export const simulatePopulateArea = function (area) {
  const chosenPanel = this.getPanelById(area.panelId, area.orientation);
  const panelWidth = chosenPanel.size.width;
  const panelHeight = chosenPanel.size.height;
  const panelSpacingX = area.horizontalSpacing / 100;
  const panelSpacingY = area.verticalSpacing / 100;

  const rightMostPoint = this.getRightMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );
  const leftMostPoint = this.getLeftMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );
  const topMostPoint = this.getTopMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );
  const bottomMostPoint = this.getBottomMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );

  const areaWidth = leftMostPoint.distanceTo(rightMostPoint);
  const areaHeight = bottomMostPoint.distanceTo(topMostPoint);
  const columns = Math.floor(areaWidth / (panelWidth + panelSpacingX));
  const rows = Math.floor(areaHeight / (panelHeight + panelSpacingY));

  const xAxis = new THREE.Vector3(1, 0, 0);
  const xDirectionInPlane = this.getAxisDirectionInPlane(
    area.simulatedCamera,
    xAxis,
    area.normal
  );

  const yAxis = new THREE.Vector3(0, 1, 0);
  const yDirectionInPlane = this.getAxisDirectionInPlane(
    area.simulatedCamera,
    yAxis,
    area.normal
  );

  const projectedTopLeftPoint = this.calculateProjectedPoint(
    topMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    true
  );

  const projectedTopRightPoint = this.calculateProjectedPoint(
    topMostPoint,
    rightMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    true,
    true
  );
  const projectedBottomLeftPoint = this.calculateProjectedPoint(
    bottomMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    false
  );

  const horizontalVector = projectedTopRightPoint
    .clone()
    .sub(projectedTopLeftPoint)
    .normalize();

  const verticalVector = projectedTopLeftPoint
    .clone()
    .sub(projectedBottomLeftPoint)
    .normalize();

  let startPos = projectedTopLeftPoint.clone();

  const verticalScalar = panelHeight + panelSpacingY;
  const horizontalScalar = panelWidth + panelSpacingX;

  const testPanel = this.createTempPanel(chosenPanel);

  const offset = area.normal.clone().multiplyScalar(area.offset / 100);

  const placementIterations = [
    roundVector(startPos),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
    ),
    roundVector(
      startPos
        .clone()
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
  ];

  const placementIterationsResults = placementIterations.map((startPos) => {
    return this.calculatePanelPlacement({
      startPos,
      rows,
      columns,
      verticalVector,
      horizontalVector,
      verticalScalar,
      horizontalScalar,
      normal: area.normal,
      ground: verticalVector,
      offset,
      panel: testPanel,
      areaPoints: area.innerPlane.points,
      areaEdges: area.innerPlane.edges,
      simulatedCamera: area.simulatedCamera,
    });
  });

  const stepX = horizontalVector.clone().multiplyScalar(horizontalScalar);
  const stepY = verticalVector.clone().multiplyScalar(verticalScalar);
  const centerPlacementResult = this.calculatePanelPlacementFromCenter({
    startPos: area.center,
    stepX,
    stepY,
    normal: area.normal,
    ground: verticalVector,
    offset,
    panel: testPanel,
    areaPoints: area.innerPlane.points,
    areaEdges: area.innerPlane.edges,
    areaIndices: area.indices,
    simulatedCamera: area.simulatedCamera,
  });
  placementIterationsResults.push(centerPlacementResult);

  const optimalPlacement = placementIterationsResults.reduce((prev, current) =>
    prev.count > current.count ? prev : current
  );

  let visiblePanels = 0;

  const tempMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempTarget = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  for (let position of optimalPlacement.grid) {
    tempPosition.set(position.x, position.y, position.z);
    tempTarget.copy(tempPosition).add(area.normal);

    const tempMatrixLookAt = new THREE.Matrix4().lookAt(
      tempPosition,
      tempTarget,
      verticalVector
    );

    tempQuaternion.setFromRotationMatrix(tempMatrixLookAt);

    tempMatrix.compose(tempPosition, tempQuaternion, tempScale);

    if (area.currentRotation) {
      tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);
      tempPosition.sub(area.areaCenter);

      const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
        area.normal,
        area.currentRotation
      );
      tempPosition.applyMatrix4(rotationMatrix);
      tempQuaternion.premultiply(
        new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
      );

      tempPosition.add(area.areaCenter);
      tempMatrix.compose(tempPosition, tempQuaternion, tempScale);
    }

    tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    if (
      this.panelInsideArea(
        tempPositionWithoutOffset,
        tempQuaternion,
        testPanel,
        verticalVector,
        area.normal,
        area.simulatedCamera,
        area.innerPlane
      )
    ) {
      const { panelVertices, panelEdges } = this.createPanelAt({
        panel: testPanel,
        pos: tempPositionWithoutOffset,
        rotation: tempQuaternion,
        ground: verticalVector,
        normal: area.normal,
      });

      let panelVisible = true;
      for (const restrictedArea of area.restrictedAreas) {
        const restrictedAreaPoints = restrictedArea.points.map(
          (point) =>
            new THREE.Vector3(
              point.position.x,
              point.position.y,
              point.position.z
            )
        );

        if (
          this.isRectangleIntersectingArea(
            panelVertices,
            panelEdges,
            restrictedAreaPoints,
            restrictedArea.edges,
            area.simulatedCamera
          )
        ) {
          panelVisible = false;
        }
      }
      if (panelVisible) visiblePanels++;
    }
  }
  return visiblePanels;
};

export const addOffsetToPanels = function (area, offsetValue) {
  const normal = this.calculatePlaneNormal(area.innerPlane.points);

  const offset = normal.clone().multiplyScalar(offsetValue / 100);

  const instancedMesh = area.instancedMesh;

  if (!instancedMesh || instancedMesh.instancesCount === 0) return;

  const tempMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, tempMatrix);

    tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);
    tempPosition.sub(offset);
    tempMatrix.compose(tempPosition, tempQuaternion, tempScale);
    instancedMesh.setMatrixAt(i, tempMatrix);
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const getInnerPlanePoints = function (area, normal, camera) {
  const areaPointsVectors = area.points.map((point) => point.position);

  if (area.margin === 0) return areaPointsVectors;

  const translatedLines = area.lines.map((line) =>
    this.translateLine(
      line,
      normal,
      camera,
      areaPointsVectors,
      area.margin / 100
    )
  );

  const innerPlanePoints = [];

  for (let i = 0; i < translatedLines.length; i++) {
    const line1 = translatedLines[i === 0 ? translatedLines.length - 1 : i - 1];
    const line2 = translatedLines[i];

    const intersectionPoint = this.findIntersectionBetweenLines(
      line1.start,
      line1.end,
      line2.start,
      line2.end
    );
    innerPlanePoints.push(intersectionPoint);
  }

  return innerPlanePoints;
};

export const translateLine = function (
  line,
  normal,
  camera,
  areaPoints,
  margin
) {
  let { translatedFirstPoint, translatedSecondPoint } =
    this.getTranslatedPointsForLine(line, normal, margin);

  const translatedMidpoint = new THREE.Vector3().lerpVectors(
    translatedFirstPoint,
    translatedSecondPoint,
    0.5
  );

  if (!this.isPointWithinArea(translatedMidpoint, areaPoints, camera)) {
    const translatedPoint = this.getTranslatedPointsForLine(
      line,
      normal,
      margin,
      true
    );
    translatedFirstPoint = translatedPoint.translatedFirstPoint;
    translatedSecondPoint = translatedPoint.translatedSecondPoint;
  }

  const traslatedLine = {
    start: translatedFirstPoint,
    end: translatedSecondPoint,
  };

  return traslatedLine;
};

export const getTranslatedPointsForLine = function (
  line,
  normal,
  margin,
  flip = false
) {
  const { firstPoint, secondPoint } = line;
  const direction = new THREE.Vector3().subVectors(
    firstPoint.position,
    secondPoint.position
  );
  const perpendicular = new THREE.Vector3()
    .crossVectors(direction, normal)
    .normalize();

  if (flip) perpendicular.negate();

  const translatedFirstPoint = firstPoint.position
    .clone()
    .addScaledVector(perpendicular, margin);
  const translatedSecondPoint = secondPoint.position
    .clone()
    .addScaledVector(perpendicular, margin);

  return { translatedFirstPoint, translatedSecondPoint };
};

export const pointIntersectsPlane = function (point, plane, normal) {
  // const cameraPosition = camera.position.clone();
  const pointPosition = point.clone();
  const rayDirection = normal.clone();

  this.raycaster.set(pointPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(plane);
  if (intersects.length > 0) return true;

  const geometry = new THREE.SphereGeometry(0.1, 32, 32);
  const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.copy(point);
  sphere.material.depthTest = false;
  sphere.renderOrder = Infinity;
  this.scene.add(sphere);

  return false;
};

export const createInnerPlane = function (points, stencilCount) {
  const geometry = new THREE.BufferGeometry();

  const pointsAsArrays = points.map((point) => [point.x, point.y, point.z]);

  const flatPoints = [].concat(...pointsAsArrays);

  const vertices = new Float32Array(flatPoints);

  const triangleIndices = this.getTriangleIndices(
    points.map((point) => {
      return {
        position: point,
      };
    }),
    this.getAxisDifferences(points)
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    stencilWrite: true,
    stencilFunc: THREE.AlwaysStencilFunc,
    stencilRef: stencilCount,
    stencilZPass: THREE.ReplaceStencilOp,
    transparent: true,
    opacity: 0.25,
  });

  const plane = new THREE.Mesh(geometry, material);

  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.INNER_SOLAR_PLANE;

  plane.points = points;

  return plane;
};

export const calculatePlaneNormal = function (points) {
  const point1 = points[0];
  const point2 = points[1];
  const point3 = points[2];

  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();

  const cameraToPlane = new THREE.Vector3();
  cameraToPlane.subVectors(point1, this.camera.position).normalize();

  const dotProduct = normal.dot(cameraToPlane);

  if (dotProduct < 0) {
    normal.negate();
  }
  return normal;
};

export const getInPlaneVector = function (normal, edge) {
  const inPlaneVector = new THREE.Vector3()
    .crossVectors(normal, edge)
    .normalize();

  return inPlaneVector;
};

export const getPanelById = function (panelId, orientation) {
  let panels = orientation
    ? this.verticalPanelTypes
    : this.horizontalPanelTypes;
  let panel = panels.find((panel) => panel.id === panelId);
  if (!panel) {
    panels =
      this.customPanels.filter(
        (panel) =>
          panel.orientation === (orientation ? "vertical" : "horizontal")
      ) || [];
    panel = panels.find((panel) => panel.id === panelId);
  }
  if (!panel) panel = panels[0];
  return panel;
};

export const getLongestEdge = function (points) {
  let longestEdge = null;
  let longestEdgeLength = 0;
  for (let i = 0; i < points.length; i++) {
    const edge = new THREE.Vector3().subVectors(
      points[i],
      points[(i + 1) % points.length]
    );
    const edgeLength = edge.length();
    if (edgeLength > longestEdgeLength) {
      longestEdgeLength = edgeLength;
      longestEdge = edge;
    }
  }
  return longestEdge;
};

export const createTrashIcon = function (panel, addCounter = true) {
  const iconMaterial = new THREE.MeshBasicMaterial({
    map: this.closeTexture,
    transparent: true,
  });

  const trashDimension =
    this.trashSize.width * Math.min(panel.size.width, panel.size.height);

  const iconGeomtery = new THREE.PlaneGeometry(trashDimension, trashDimension);
  const icon = new THREE.Mesh(iconGeomtery, iconMaterial);
  icon.position.set(
    panel.size.width / 2 - trashDimension / 2,
    panel.size.height / 2 - trashDimension / 2,
    0
  );

  icon.material.depthTest = false;
  icon.renderOrder = RENDERING_ORDER.PANEL_TRASH;
  if (addCounter) icon.renderOrder += this.panelCounter;
  icon.visible = false;

  return icon;
};

export const flatArrayToVectors3D = function (flatArray) {
  const vectorArray = [];
  for (let i = 0; i < flatArray.length; i = i + 3) {
    vectorArray.push(
      new THREE.Vector3(flatArray[i], flatArray[i + 1], flatArray[i + 2])
    );
  }
  return vectorArray;
};

export const createPanelObject = async function (
  areaId,
  point,
  panelId,
  orientation,
  renderOrder
) {
  if (this.sample) return;
  const panelObject = {
    projectId: Number(this.projectId),
    areaId,
    position: point,
    panelId,
    orientation,
    renderOrder,
  };
  return await API.airteam3DViewer.createPanelObject(panelObject);
};

export const createBulkPanelObjects = async function (array) {
  if (this.sample) return;
  return await API.airteam3DViewer.createBulkPanelObjects(array);
};

export const updatePanelObject = async function (
  id,
  areaId,
  point,
  panelId,
  orientation,
  renderOrder
) {
  if (this.sample) return;
  const panelObject = {
    id,
    projectId: Number(this.projectId),
    areaId,
    position: point,
    panelId,
    orientation,
    renderOrder,
  };
  return await API.airteam3DViewer.updatePanelObject(panelObject);
};

export const updateBulkPanelObjects = async function (array) {
  if (this.sample) return;

  return await API.airteam3DViewer.updateBulkPanelObject(array);
};

export const deletePanelObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const deleteBulkPanelObjects = async function (array) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteBulkPanelObjects(array);
};

export const getDefaultPanelTypes = async function () {
  try {
    const res = await API.airteam3DViewer.getDefaultPanelTypes();
    const defaultPanels = [];

    res.data.forEach((panel) => {
      const originalPanel = {
        ...panel,
        texture: null,
        orientation:
          panel.size.height > panel.size.width ? VERTICAL : HORIZONTAL,
        size: {
          height: panel.size.height / 1000,
          width: panel.size.width / 1000,
        },
      };

      const flippedPanel = {
        ...originalPanel,
        size: {
          height: originalPanel.size.width,
          width: originalPanel.size.height,
        },
        orientation:
          originalPanel.orientation === VERTICAL ? HORIZONTAL : VERTICAL,
      };

      defaultPanels.push(originalPanel, flippedPanel);
    });

    return defaultPanels;
  } catch (error) {
    console.error("Failed to fetch default panels:", error);
  }
};

export const getShiftedCenterPoint = function (points, inPlaneVector, normal) {
  const stepSize = 0.2;

  const vectorPoints = points.map((point) => point.position);

  const leftMostPoint = this.getLeftMostPoint(vectorPoints);
  const bottomMostPoint = this.getBottomMostPoint(vectorPoints);
  const centerPoint = this.getCenterPoint(points);

  const projectedTopLeftPoint = leftMostPoint
    .clone()
    .add(
      inPlaneVector
        .clone()
        .multiplyScalar(
          bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVector)
        )
    );

  const pos = centerPoint
    .clone()
    // move the panel vertically
    .add(inPlaneVector.clone().multiplyScalar(stepSize * this.callCount))
    // move the panel horizontally from center Point to topLeft point
    .add(
      projectedTopLeftPoint
        .clone()
        .sub(centerPoint)
        .normalize()
        .multiplyScalar(stepSize * this.callCount)
    )
    // ensure the panel is centered along the horizontal direction
    .sub(
      projectedTopLeftPoint
        .clone()
        .sub(centerPoint)
        .normalize()
        .multiplyScalar(stepSize)
    )
    // ensure the panel is sits on the plane, not just parallel to it
    .sub(normal.clone().multiplyScalar(0));

  this.callCount = this.callCount + 1;

  return pos;
};

export const getShiftedPoint = function (
  points,
  inPlaneVector,
  normal,
  index,
  i
) {
  const stepSize = 0.1;

  const vectorPoints = points.map((point) => point.position);

  const point = vectorPoints[index];
  const centerPoint = this.getCenterPoint(points);
  // const projectedTopLeftPoint = leftMostPoint
  //   .clone()
  //   .add(
  //     inPlaneVector
  //       .clone()
  //       .multiplyScalar(
  //         bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVector)
  //       )
  //   )
  const pos = point
    .clone()
    // move the panel vertically
    // .add(inPlaneVector.clone().multiplyScalar(stepSize * this.callCount))
    // move the panel horizontally from center Point to topLeft point
    .add(
      centerPoint
        .clone()
        .sub(point)
        .normalize()
        .multiplyScalar(0.8 + stepSize * this.callCount + i)
    )
    // ensure the panel is centered along the horizontal direction
    .sub(centerPoint.clone().sub(point).normalize().multiplyScalar(stepSize))
    // ensure the panel is sits on the plane, not just parallel to it
    .sub(normal.clone().multiplyScalar(0));

  return pos;
};

export const getRightMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let rightMostPoint = screenPoints[0];
  let rightMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (rightMostPoint.x > screenPoints[i].x) {
      rightMostPoint = screenPoints[i];
      rightMostPointIndex = i;
    }
  }
  return points[rightMostPointIndex];
};

export const getLeftMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let leftMostPoint = screenPoints[0];
  let leftMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (leftMostPoint.x < screenPoints[i].x) {
      leftMostPoint = screenPoints[i];
      leftMostPointIndex = i;
    }
  }
  return points[leftMostPointIndex];
};

export const getTopMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let topMostPoint = screenPoints[0];
  let topMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (topMostPoint.y > screenPoints[i].y) {
      topMostPoint = screenPoints[i];
      topMostPointIndex = i;
    }
  }
  return points[topMostPointIndex];
};

export const getBottomMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let bottomMostPoint = screenPoints[0];
  let bottomMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (bottomMostPoint.y < screenPoints[i].y) {
      bottomMostPoint = screenPoints[i];
      bottomMostPointIndex = i;
    }
  }
  return points[bottomMostPointIndex];
};

export const isPointWithinArea = function (
  point,
  areaVertices,
  simulatedCamera
) {
  const areaCoordinates = [];

  for (let i = 0; i < areaVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      areaVertices[i].clone(),
      simulatedCamera
    );
    areaCoordinates.push([screenPosition.x, screenPosition.y]);
  }
  const polygon = turf.polygon([[...areaCoordinates, areaCoordinates[0]]]);

  const screenPosition = this.toScreenPosition(point.clone(), simulatedCamera);
  const turfPoint = turf.point([screenPosition.x, screenPosition.y]);

  return turf.booleanPointInPolygon(turfPoint, polygon);
};

export const verticesToScreenCoordinates = function (
  vertices,
  simulatedCamera
) {
  const screenCoordinates = [];
  for (let i = 0; i < vertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      vertices[i].clone(),
      simulatedCamera
    );
    screenCoordinates.push([screenPosition.x, screenPosition.y]);
  }
  return screenCoordinates;
};

export const isRectangleIntersectingArea = function (
  panelVertices,
  panelEdges,
  areaVertices,
  areaEdges,
  simulatedCamera
) {
  // check if panel lies inside restricted area
  const areaCoordinates = this.verticesToScreenCoordinates(
    areaVertices,
    simulatedCamera
  );

  const polygon = turf.polygon([[...areaCoordinates, areaCoordinates[0]]]);

  for (let i = 0; i < panelVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      panelVertices[i].clone(),
      simulatedCamera
    );
    const point = turf.point([screenPosition.x, screenPosition.y]);

    if (turf.booleanPointInPolygon(point, polygon)) {
      return true;
    }
  }

  // check if restricted area lies inside panel
  const panelCoordinates = this.verticesToScreenCoordinates(
    panelVertices,
    simulatedCamera
  );
  const panelPolygon = turf.polygon([
    [...panelCoordinates, panelCoordinates[0]],
  ]);

  for (let i = 0; i < areaVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      areaVertices[i].clone(),
      simulatedCamera
    );
    const point = turf.point([screenPosition.x, screenPosition.y]);

    if (turf.booleanPointInPolygon(point, panelPolygon)) {
      return true;
    }
  }

  // check if restricted area intersect panel
  for (let i = 0; i < panelEdges.length; i++) {
    const panelEdgeStart = this.toScreenPosition(
      panelEdges[i][0].clone(),
      simulatedCamera
    );
    const panelEdgeEnd = this.toScreenPosition(
      panelEdges[i][1].clone(),
      simulatedCamera
    );

    const panelLine = turf.lineString([
      [panelEdgeStart.x, panelEdgeStart.y],
      [panelEdgeEnd.x, panelEdgeEnd.y],
    ]);

    for (let j = 0; j < areaEdges.length; j++) {
      const areaEdgeStart = this.toScreenPosition(
        areaEdges[j][0].clone(),
        simulatedCamera
      );
      const areaEdgeEnd = this.toScreenPosition(
        areaEdges[j][1].clone(),
        simulatedCamera
      );

      const areaLine = turf.lineString([
        [areaEdgeStart.x, areaEdgeStart.y],
        [areaEdgeEnd.x, areaEdgeEnd.y],
      ]);

      if (turf.lineIntersect(panelLine, areaLine).features.length > 0) {
        return true;
      }
    }
  }

  // Manual Check if the restricted area is inside the panel because turf sometimes fails with very small areas
  const centroid = turf.centroid(polygon);
  const areaCenter = centroid.geometry.coordinates;

  const panelMinX = Math.min(...panelCoordinates.map((pc) => pc[0]));
  const panelMaxX = Math.max(...panelCoordinates.map((pc) => pc[0]));
  const panelMinY = Math.min(...panelCoordinates.map((pc) => pc[1]));
  const panelMaxY = Math.max(...panelCoordinates.map((pc) => pc[1]));

  if (
    areaCenter[0] > panelMinX &&
    areaCenter[0] < panelMaxX &&
    areaCenter[1] > panelMinY &&
    areaCenter[1] < panelMaxY
  ) {
    return true;
  }

  return false;
};

export const isRectangleWithinPoints = function (
  panelVertices,
  panelEdges,
  areaVertices,
  areaEdges,
  simulatedCamera
) {
  const areaCoordinates = this.verticesToScreenCoordinates(
    areaVertices,
    simulatedCamera
  );

  const polygon = turf.polygon([[...areaCoordinates, areaCoordinates[0]]]);

  for (let i = 0; i < panelVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      panelVertices[i].clone(),
      simulatedCamera
    );
    const point = turf.point([screenPosition.x, screenPosition.y]);

    if (!turf.booleanPointInPolygon(point, polygon)) {
      return false;
    }
  }

  // check if edges are within area
  for (let i = 0; i < panelEdges.length; i++) {
    const panelEdgeStart = this.toScreenPosition(
      panelEdges[i][0].clone(),
      simulatedCamera
    );
    const panelEdgeEnd = this.toScreenPosition(
      panelEdges[i][1].clone(),
      simulatedCamera
    );

    for (let j = 0; j < areaEdges.length; j++) {
      const areaEdgeStart = this.toScreenPosition(
        areaEdges[j][0].clone(),
        simulatedCamera
      );
      const areaEdgeEnd = this.toScreenPosition(
        areaEdges[j][1].clone(),
        simulatedCamera
      );

      const line1 = turf.lineString([
        [panelEdgeStart.x, panelEdgeStart.y],
        [panelEdgeEnd.x, panelEdgeEnd.y],
      ]);
      const line2 = turf.lineString([
        [areaEdgeStart.x, areaEdgeStart.y],
        [areaEdgeEnd.x, areaEdgeEnd.y],
      ]);

      if (turf.lineIntersect(line1, line2).features.length > 0) {
        return false;
      }
    }
  }
  return true;
};

export const toScreenPosition = function (vector, camera) {
  vector.project(camera);

  vector.x = Math.round(
    ((vector.x + 1) * this.renderer.getContext().canvas.width) / 2
  );
  vector.y = Math.round(
    ((-vector.y + 1) * this.renderer.getContext().canvas.height) / 2
  );
  vector.z = 0;
  return {
    x: vector.x,
    y: vector.y,
  };
};

export const isInside = function (point, vs, indices) {
  // Triangulate the polygon
  const triangles = [];
  for (let i = 0; i < indices.length; i++) {
    if (triangles.length > 0 && triangles[triangles.length - 1].length < 3) {
      triangles[triangles.length - 1].push(vs[indices[i]]);
    } else {
      triangles.push([vs[indices[i]]]);
    }
  }

  // Check if the point is inside any of the triangles
  for (const triangle of triangles) {
    const [v1, v2, v3] = triangle;
    const a =
      (1 / 2) *
      (-v2.y * v3.x +
        v1.y * (-v2.x + v3.x) +
        v1.x * (v2.y - v3.y) +
        v2.x * v3.y);
    const sign = a < 0 ? -1 : 1;
    const s =
      (v1.y * v3.x -
        v1.x * v3.y +
        (v3.y - v1.y) * point.x +
        (v1.x - v3.x) * point.y) *
      sign;
    const t =
      (v1.x * v2.y -
        v1.y * v2.x +
        (v1.y - v2.y) * point.x +
        (v2.x - v1.x) * point.y) *
      sign;
    if (s > 0 && t > 0 && s + t < 2 * a * sign) {
      return true;
    }
  }

  return false;
};

export const undoAddPanel = async function (panel, area) {
  // add action to redo stack
  this.redoStack.push({ action: "ADD_PANEL", panel: panel, area: area });

  const panelObject = this.scene.getObjectById(panel.plane.id);
  area.panels = area.panels.filter((p) => p.plane.id !== panel.plane.id);
  panelObject.visible = false;

  await this.deletePanelObject(panel.id);
};

export const redoAddPanel = async function (panel, area) {
  // add action to undo stack
  this.undoStack.push({ action: "ADD_PANEL", panel: panel, area: area });

  const panelObject = this.scene.getObjectById(panel.plane.id);

  if (!this.anonymousUser) {
    try {
      const { data } = await this.createPanelObject(
        area.id,
        {
          x: panelObject.position.x,
          y: panelObject.position.y,
          z: panelObject.position.z,
        },
        panel.panelId,
        panel.orientation,
        panel.plane.renderOrder
      );
      panel.id = data;
    } catch (e) {}
  }

  area.panels.push(panel);
  panelObject.visible = true;
};

export const undoBulkAddPanels = async function (panels, area) {
  // add action to redo stack
  this.redoStack.push({
    action: "BULK_ADD_PANELS",
    panels: panels,
    area: area,
  });

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    area.panels = area.panels.filter((p) => p.plane.id !== panel.plane.id);
    panelObject.visible = false;

    panelPromises.push(this.deletePanelObject(panel.id));
  }

  Promise.all(panelPromises);
};

export const redoBulkAddPanels = async function (panels, area) {
  // add action to undo stack
  this.undoStack.push({
    action: "BULK_ADD_PANELS",
    panels: panels,
    area: area,
  });

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    area.panels.push(panel);
    panelObject.visible = true;

    panelPromises.push(
      this.createPanelObject(
        area.id,
        {
          x: panelObject.position.x,
          y: panelObject.position.y,
          z: panelObject.position.z,
        },
        panel.panelId,
        panel.orientation,
        panel.plane.renderOrder
      )
    );
  }
  Promise.all(panelPromises);
};

export const undoMovePanels = async function (panels, area) {
  for (let panel of panels) {
    const currentPositon = {
      x: panel.plane.position.x,
      y: panel.plane.position.y,
      z: panel.plane.position.z,
    };
    panel.plane.currentPosition = currentPositon;
  }

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.position.x = panelObject.prevPosition.x;
    panelObject.position.y = panelObject.prevPosition.y;
    panelObject.position.z = panelObject.prevPosition.z;

    panelObject.prevPosition.x = panelObject.currentPosition.x;
    panelObject.prevPosition.y = panelObject.currentPosition.y;
    panelObject.prevPosition.z = panelObject.currentPosition.z;

    panelObject.visible = true;

    await this.updatePanelObject(
      panel.id,
      area.id,
      {
        x: panelObject.position.x,
        y: panelObject.position.y,
        z: panelObject.position.z,
      },
      panel.panelId,
      panel.orientation,
      panelObject.renderOrder
    );

    if (area.panels.find((p) => p.plane.id === panel.plane.id)) {
      let index = area.panels.findIndex((p) => p.plane.id === panel.plane.id);
      area.panels.splice(index, 1, panel);
    } else {
      area.panels.push(panel);
    }
  }

  for (let panel of area.panels) {
    panel.selected = false;
  }
  // add action to redo stack
  this.redoStack.push({
    action: "MOVE_PANELS",
    panels: panels,
    area: area,
  });
};

export const redoMovePanels = async function (panels, area) {
  for (let panel of panels) {
    const currentPositon = {
      x: panel.plane.position.x,
      y: panel.plane.position.y,
      z: panel.plane.position.z,
    };
    panel.plane.currentPosition = currentPositon;
  }
  const oldPanels = [].concat(panels);

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.position.x = panelObject.prevPosition.x;
    panelObject.position.y = panelObject.prevPosition.y;
    panelObject.position.z = panelObject.prevPosition.z;

    panelObject.prevPosition.x = panelObject.currentPosition.x;
    panelObject.prevPosition.y = panelObject.currentPosition.y;
    panelObject.prevPosition.z = panelObject.currentPosition.z;

    if (panel.deleted) {
      panelObject.visible = false;

      panelPromises.push(this.deletePanelObject(panel.id));
    } else {
      panelPromises.push(
        this.updatePanelObject(
          panel.id,
          area.id,
          {
            x: panelObject.position.x,
            y: panelObject.position.y,
            z: panelObject.position.z,
          },
          panel.panelId,
          panel.orientation,
          panelObject.renderOrder
        )
      );
    }
  }

  Promise.all(panelPromises);

  area.panels = area.panels.filter((panel) => !panel.deleted);

  for (let panel of area.panels) {
    panel.selected = false;
  }

  // add action to redo stack
  this.undoStack.push({
    action: "MOVE_PANELS",
    panels: oldPanels,
    area: area,
  });
};

export const undoDeletePanels = async function (panels, area) {
  // add action to redo stack
  this.redoStack.push({
    action: "DELETE_PANELS",
    panels: panels,
    area: area,
  });

  // execute undo action
  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);

    if (!this.anonymousUser) {
      try {
        const { data } = await this.createPanelObject(
          area.id,
          {
            x: panelObject.position.x,
            y: panelObject.position.y,
            z: panelObject.position.z,
          },
          panel.panelId,
          panel.orientation,
          panel.plane.renderOrder
        );
        panel.id = data;
      } catch (e) {}
    }

    panelObject.visible = true;
  }
  area.panels.push(...panels);
  area.panels.forEach((panel) => (panel.selected = false));
};

export const redoDeletePanels = function (panels, area) {
  // add action to redo stack
  this.undoStack.push({
    action: "DELETE_PANELS",
    panels: panels,
    area: area,
  });

  // execute redo action
  this.hidePanels(panels, area);
  area.panels.forEach((panel) => (panel.selected = false));
};

export const displayPanel = function (panel) {
  let areaObject;
  this.areas.forEach((area) => {
    if (area.id == panel.areaId) {
      areaObject = area;
    }
  });
  if (!areaObject) return;
  const panelPlanes = this.verticalPanelTypes
    .concat(this.horizontalPanelTypes)
    .concat(this.customPanels);
  let panelId;
  const firstVerticalPanel = panelPlanes.find(
    (panelPlane) => panelPlane.orientation === VERTICAL
  );
  this.defaultVerticalTexture = firstVerticalPanel.texture;
  this.defaultHorizontalTexture = panelPlanes.find(
    (panelPlane) =>
      panelPlane.id === firstVerticalPanel.id &&
      panelPlane.orientation === HORIZONTAL
  ).texture;
  panelPlanes.forEach((panelPlane) => {
    // backwards compatibility for old panels without panelId
    if (
      !panel.panelId &&
      panel.size.width == panelPlane.size.width &&
      panel.size.height == panelPlane.size.height
    ) {
      panel.texture = panelPlane.texture;
      panel.orientation = panelPlane.orientation;
      panel.panelId = panelPlane.id;
    } else if (
      panel.panelId &&
      panel.panelId == panelPlane.id &&
      panel.orientation == panelPlane.orientation
    ) {
      panelId = panelPlane.id;
      panel.texture = panelPlane.texture;
      panel.size = {};
      panel.size.height = panelPlane.size.height;
      panel.size.width = panelPlane.size.width;
    }
  });
  if (!panelPlanes.includes(panelId)) {
    panel.texture = this.isPanelVertical(panel)
      ? this.defaultVerticalTexture
      : this.defaultHorizontalTexture;
    panel.orientation == this.getPanelOrientation(panel);
  }
  const points = areaObject?.points.map((point) => point.position);
  const point1 = new THREE.Vector3(
    areaObject?.points[0]?.position?.x,
    areaObject?.points[0]?.position?.y,
    areaObject?.points[0]?.position?.z
  );
  const point2 = new THREE.Vector3(
    areaObject?.points[1]?.position?.x,
    areaObject?.points[1]?.position?.y,
    areaObject?.points[1]?.position?.z
  );
  const point3 = new THREE.Vector3(
    areaObject?.points[2]?.position?.x,
    areaObject?.points[2]?.position?.y,
    areaObject?.points[2]?.position?.z
  );
  // Find the longest edge
  let longestEdge = null;
  let longestEdgeLength = 0;
  for (let i = 0; i < points?.length; i++) {
    const edge = new THREE.Vector3().subVectors(
      points[i],
      points[(i + 1) % points.length]
    );
    const edgeLength = edge.length();
    if (edgeLength > longestEdgeLength) {
      longestEdgeLength = edgeLength;
      longestEdge = edge;
    }
  }
  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();
  const inPlaneVector = new THREE.Vector3()
    .crossVectors(normal, longestEdge)
    .normalize();
  const planeGeometry = new THREE.PlaneGeometry(
    panel.size.width,
    panel.size.height
  );
  let planeMaterial;
  let needsUpdate = false;
  if (this.texturesLoading || isPanelSmall(panel.size)) {
    planeMaterial = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: areaObject?.transparencyLevel / 100,
    });
    needsUpdate = true;
  } else {
    planeMaterial = new THREE.MeshBasicMaterial({
      map: panel.texture,
      transparent: true,
      opacity: areaObject?.transparencyLevel / 100,
    });
  }
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.material.depthTest = false;
  plane.renderOrder =
    panel.renderOrder || RENDERING_ORDER.PANEL + this.panelCounter;
  const panelPosition = new THREE.Vector3(
    panel?.position?.x,
    panel?.position?.y,
    panel?.position?.z
  );

  const toCamera = new THREE.Vector3()
    .subVectors(this.camera.position, panelPosition)
    .normalize();

  if (normal.dot(toCamera) < 0) {
    normal.negate();
  }

  const offsetDistance = 0.05;
  const offset = normal.clone().multiplyScalar(offsetDistance);
  const offsetPosition = panelPosition.clone().add(offset);

  plane.position.copy(offsetPosition);
  plane.up.copy(inPlaneVector);
  plane.lookAt(offsetPosition.clone().add(normal));
  const geometry = plane.geometry.clone();
  plane.updateMatrix();
  plane.updateWorldMatrix();
  geometry.applyMatrix4(plane.matrixWorld);

  const iconMaterial = new THREE.MeshBasicMaterial({
    map: this.closeTexture,
    transparent: true,
  });

  const trashDimension =
    this.trashSize.width * Math.min(panel.size.width, panel.size.height);

  const iconGeomtery = new THREE.PlaneGeometry(trashDimension, trashDimension);
  const icon = new THREE.Mesh(iconGeomtery, iconMaterial);
  icon.position.set(
    panel.size.width / 2 - trashDimension / 2,
    panel.size.height / 2 - trashDimension / 2,
    0
  );
  icon.material.depthTest = false;
  icon.renderOrder =
    panel.renderOrder + 1 || RENDERING_ORDER.PANEL + this.panelCounter + 1;
  icon.visible = true;
  plane.add(icon);
  this.scene.add(plane);
  const planePanel = {
    id: panel.id,
    plane: plane,
    icon: icon,
    size: {
      width: panel.size.width,
      height: panel.size.height,
    },
    panelId: panel.panelId,
    orientation: panel.orientation,
    placedBefore: true,
  };
  planePanel.icon.visible = false;
  areaObject?.panels.push(planePanel);
  if (needsUpdate) this.panelsToUpdate.push(planePanel);
  this.hideAreaPoints(areaObject);
  this.disablePointDragMode();
  this.isEditButtonDisplayed = areaObject?.panels?.length > 0;
  this.panelCounter++;
};

export const loadCustomPanelTextures = async function () {
  const textureLoader = new THREE.TextureLoader();
  const textureUrls = [
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
  ];

  const texturePromises = textureUrls.map(
    (textureObj, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + textureObj.url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        this.customPanels
          .filter((panel) => panel.textureId == textureObj.id)
          .forEach((panel) => {
            if (index % 2 === 0) {
              if (panel.orientation === VERTICAL) {
                panel.texture = textureMap;
              }
            } else {
              if (panel.orientation === HORIZONTAL) {
                panel.texture = textureMap;
              }
            }
          });
      })
  );

  Promise.all(texturePromises)
    .then(() => {
      this.texturesLoading = false;
    })
    .catch((error) => {
      console.error("Error loading textures:", error);
    });
};

export const loadPanelTextures = async function () {
  const textureLoader = new THREE.TextureLoader();
  const textureUrls = [];
  for (let i = 0; i < this.horizontalPanelTypes.length; i++) {
    const id = this.horizontalPanelTypes[i].textureId;
    let horizontalTexture = `solar_panel_texture_half_cell_${id}_horizontal.png`;
    textureUrls.push(horizontalTexture, horizontalTexture);
  }

  const customTextureUrls = [
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
  ];

  const defaultPanelsTexturePromises = textureUrls.map(
    (url, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        if (index % 2 === 0) {
          this.verticalPanelTypes[index / 2].texture = textureMap;
        } else {
          this.horizontalPanelTypes[Math.floor(index / 2)].texture = textureMap;
        }
      })
  );

  const customPanelsTexturePromises = customTextureUrls.map(
    (textureObj, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + textureObj.url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        this.customPanels
          .filter((panel) => panel.textureId == textureObj.id)
          .forEach((panel) => {
            if (index % 2 === 0) {
              if (panel.orientation === VERTICAL) {
                panel.texture = textureMap;
              }
            } else {
              if (panel.orientation === HORIZONTAL) {
                panel.texture = textureMap;
              }
            }
          });
      })
  );

  const texturePromises = [].concat(
    defaultPanelsTexturePromises,
    customPanelsTexturePromises
  );

  Promise.all(texturePromises)
    .then(() => {
      this.texturesLoading = false;
      const panelPlanes = this.verticalPanelTypes
        .concat(this.horizontalPanelTypes)
        .concat(this.customPanels);
      this.panelsToUpdate.forEach((panel) => {
        panelPlanes.forEach((panelPlane) => {
          // backwards compatibility for old panels without panelId
          if (
            !panel.panelId &&
            panel.size.width == panelPlane.size.width &&
            panel.size.height == panelPlane.size.height
          ) {
            panel.texture = panelPlane.texture;
            panel.orientation = panelPlane.orientation;
            panel.panelId = panelPlane.id;
          } else if (
            panel.panelId &&
            panel.panelId == panelPlane.id &&
            panel.orientation == panelPlane.orientation
          ) {
            panel.texture = panelPlane.texture;
            panel.size = {};
            panel.size.height = panelPlane.size.height;
            panel.size.width = panelPlane.size.width;
          }
        });
        if (!panelPlanes.includes(panel.panelId)) {
          panel.texture = this.isPanelVertical(panel)
            ? this.defaultVerticalTexture
            : this.defaultHorizontalTexture;
          panel.orientation == this.getPanelOrientation(panel);
        }
        if (panel.textureId) {
          const material = panel.plane.material;
          material.color = undefined;
          material.map = panel.texture;
          material.needsUpdate = true;
        }
      });
    })
    .catch((error) => {
      console.error("Error loading textures:", error);
    });
  this.closeTexture = new THREE.TextureLoader().load("/assets/model/trash.svg");
};

export const createPanelInstance = function (
  panelWidth,
  panelHeight,
  panelTexture,
  panelsCount
) {
  const geometry = new THREE.PlaneGeometry(panelWidth, panelHeight);
  let material = null;
  if (!panelTexture) {
    material = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: 0.5,
    });
  } else {
    material = new THREE.MeshBasicMaterial({
      map: panelTexture,
      transparent: true,
      opacity: 0.5,
    });
  }

  const instancedMesh = new InstancedMesh2(
    this.renderer,
    panelsCount,
    geometry,
    material
  );

  return instancedMesh;
};

export const createTempPanel = function (panel) {
  const geometry = new THREE.PlaneGeometry(panel.size.width, panel.size.height);
  const material = new THREE.MeshBasicMaterial({
    color: BLACK,
  });
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export const createPanelMesh = function (area, panel) {
  const geometry = new THREE.PlaneGeometry(panel.size.width, panel.size.height);

  let material;
  if (isPanelSmall(panel.size)) {
    material = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  } else {
    material = new THREE.MeshBasicMaterial({
      map: panel.texture,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  }
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export const getPanelOrientation = function (panel) {
  if (panel.size.width > panel.size.height) return HORIZONTAL;
  return VERTICAL;
};

export const isPanelVertical = function (panel) {
  if (this.getPanelOrientation(panel) === VERTICAL) return true;
  else return false;
};

export const chunkArray = function (array, chunkSize) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
};

export const getPlacementTitle = function (index) {
  switch (index) {
    case 0:
      return "DEFAULT";
    case 1:
      return "VERTICAL_OFFSET";
    case 2:
      return "HORIZONTAL_OFFSET";
    case 3:
      return "DOUBLE_OFFSET";
    case 4:
      return "CENTER";
    default:
      return "";
  }
};

export const getPlacementIndex = function (title) {
  switch (title) {
    case "DEFAULT":
      return 0;
    case "VERTICAL_OFFSET":
      return 1;
    case "HORIZONTAL_OFFSET":
      return 2;
    case "DOUBLE_OFFSET":
      return 3;
    case "CENTER":
      return 4;
    default:
      return -1;
  }
};

const createGridElement = function () {
  const element = document.createElement("img");
  element.style =
    "cursor:grab; pointer-events: all; opacity: 0.7; width: 34px;";
  element.style.transition = "opacity 0.3s ease";
  return element;
};

export const createGridLabel = function (elememt, position) {
  const label = new CSS2DObject(elememt);
  label.position.set(position.x, position.y, position.z);
  label.layers.set(0);
  label.renderOrder = RENDERING_ORDER.MOVE_GRID;
  return label;
};

export const getPanelName = function (area) {
  const panel = this.getPanelById(area.panelId, area.orientation);
  return panel?.name || "";
};

export const checkPanelsInSolarArea = function (area) {
  const {
    testPanel,
    verticalVector,
    normal,
    ground,
    simulatedCamera,
    innerPlane,
    instancedMesh,
    restrictedAreas,
  } = area;

  const tempPosition = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const transformationMatrix = new THREE.Matrix4();
  const offset = normal.clone().multiplyScalar(area.offset / 100);

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);

    transformationMatrix.decompose(
      tempPosition,
      tempQuaternion,
      new THREE.Vector3()
    );

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    if (
      !this.panelInsideArea(
        tempPositionWithoutOffset,
        tempQuaternion,
        testPanel,
        verticalVector,
        normal,
        simulatedCamera,
        innerPlane
      )
    ) {
      instancedMesh.setVisibilityAt(i, false);
    } else {
      instancedMesh.setVisibilityAt(i, true);

      const { panelVertices, panelEdges } = this.createPanelAt({
        panel: testPanel,
        pos: tempPositionWithoutOffset,
        rotation: tempQuaternion,
        ground,
        normal,
      });

      for (const restrictedArea of restrictedAreas) {
        const restrictedAreaPoints = restrictedArea.points.map(
          (point) =>
            new THREE.Vector3(
              point.position.x,
              point.position.y,
              point.position.z
            )
        );

        if (
          this.isRectangleIntersectingArea(
            panelVertices,
            panelEdges,
            restrictedAreaPoints,
            restrictedArea.edges,
            simulatedCamera
          )
        ) {
          instancedMesh.setVisibilityAt(i, false);
          break;
        }
      }
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};
