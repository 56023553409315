<template>
  <q-dialog v-model="open" @hide="$emit('helper-closed')">
    <q-card class="helper-card">
      <q-bar class="bar">
        <div class="col text-center text-weight-bold header">
          {{ $t("navigation.title") }}
        </div>
        <q-btn
          dense
          flat
          icon="minimize"
          class="mr-negative minimize text-weight-bold"
          v-close-popup
        />
      </q-bar>

      <q-card-section>
        <div class="row helper-row q-mb-md">
          <div class="col-2 col-center">
            <p class="title">
              <img src="@/assets/icons/orbit-helper.svg" />
              {{ $t("navigation.orbit") }}
            </p>
          </div>
          <div class="col-10">
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.alt')"
            />
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.leftClick')"
            />
            <span class="connector">{{ $t("navigation.or") }}</span>
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.mouseWheelClick')"
            />
            <span class="connector">{{ $t("navigation.or") }}</span>
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.rightClick')"
            />
            <span class="connector">{{ $t("navigation.or") }}</span>
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.leftMouseClick')"
            />
          </div>
        </div>
        <div class="row helper-row q-mb-md">
          <div class="col-2 col-center">
            <p class="title">
              <img src="@/assets/icons/pan-helper.svg" />
              {{ $t("navigation.pan") }}
            </p>
          </div>
          <div class="col-10">
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.shift')"
            />
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.leftRightClick')"
            />
            <span class="connector">{{ $t("navigation.or") }}</span>
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.shift')"
            />
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.mouseWheelClick')"
            />
          </div>
        </div>

        <div class="row helper-row q-mb-md">
          <div class="col-2 col-center">
            <p class="title">
              <img src="@/assets/icons/zoom-helper.svg" />
              {{ $t("navigation.zoom") }}
            </p>
          </div>
          <div class="col-10">
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.trackpad')"
            />
            <span class="connector">{{ $t("navigation.or") }}</span>
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.mouseWheelScroll')"
            />
          </div>
        </div>
        <div class="row helper-row q-mb-md">
          <div class="col-2 col-center">
            <p class="title title--small">
              <img src="@/assets/icons/focus-helper.svg" />
              {{ $t("navigation.pivot") }}
            </p>
          </div>
          <div class="col-10">
            <q-chip
              class="key"
              outline
              square
              color="white"
              text-color="white"
              :label="$t('navigation.doubleLeftClick')"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    isOpen: true,
  },
  emits: ["helper-closed"],
  data() {
    return {
      open: this.isOpen,
    };
  },
  watch: {
    isOpen(newVal) {
      this.open = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  background: #72f0b0;
}

.mr-negative {
  margin-top: -8px;
}

.minimize {
  position: absolute;
  right: 0;
}

.title {
  color: white;
  font-weight: 600;
  font-size: 20px;

  &--small {
    font-size: 17px;
  }
}

.key {
  font-size: 20px;
  margin-right: 8px;
  padding: 0;
}

.helper-card {
  width: 87%;
  max-width: 87%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: #3b3c3d;
}

.connector {
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-right: 8px;
}

.col-center {
  margin: auto;
}

.os {
  color: white;
  font-size: 15px;
  font-weight: 400;
}

.mac-icon {
  color: white;
  font-size: 22px;
  font-weight: 400;
}

.header {
  color: #424242;
  font-size: 20px;
  font-weight: 700;
}

.helper-row {
  background: #27292c;
  padding: 8px;
  border-radius: 12px;
}
</style>
