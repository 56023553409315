import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { isProduction, isStaging } from "@/utils/env";

const initializeSentry = (app) => {
  const sentryEnvironment = isStaging
    ? "Staging"
    : isProduction
    ? "Production"
    : null;
  if (sentryEnvironment === null) {
    throw new Error("Couldn't initialize Sentry environment");
  }
  Sentry.init({
    app,
    dsn: "https://4a4aa0c69ba3bf19c8185aafd3689e1d@o540004.ingest.us.sentry.io/4506772870135808",
    environment: sentryEnvironment,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    logErrors: true,
  });
};

export { initializeSentry };
