<template>
  <q-dialog v-model="isModalOpen" @hide="undoAction">
    <q-card>
      <q-card-section class="row justify-between items-center">
        <div class="row items-center">
          <img src="@/assets/icons/remove-panel-warning.svg" />
          <span class="text-h6 q-ml-sm">{{
            $t("removeCustomPanel.title")
          }}</span>
        </div>
        <img
          class="cursor-pointer"
          src="@/assets/icons/close-white.svg"
          @click="undoAction"
        />
      </q-card-section>

      <q-card-section>
        {{ $t("removeCustomPanel.content") }}
      </q-card-section>

      <q-card-actions class="q-mt-md">
        <q-btn
          class="cursor-pointer"
          flat
          label="Cancel"
          color="white"
          @click="undoAction"
          style="margin-right: auto"
        />
        <q-btn
          class="cursor-pointer"
          flat
          label="Confirm deletion"
          color="gree"
          @click="confirmDeletion"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      isModalOpen: this.showModal,
    };
  },
  watch: {
    showModal(newVal) {
      this.isModalOpen = newVal;
    },
  },
  methods: {
    undoAction() {
      this.$emit("close-modal");
    },
    confirmDeletion() {
      this.$emit("confirm-deletion");
    },
  },
};
</script>

<style scoped lang="scss">
.q-card {
  max-width: 600px;
  background: #27292c;
  color: white;
  border-radius: 12px;
}
</style>
