<template>
  <div class="row single-panel q-py-md" style="padding-left: 15px">
    <div
      v-if="!likedPanel && !anonymousUser"
      class="star-img"
      @click="handleStarClick"
    >
      <img src="@/assets/icons/starDeselected.svg" class="q-pe-md" />
    </div>
    <div
      v-if="likedPanel && !anonymousUser"
      class="star-img"
      @click="unlikePanel"
    >
      <img src="@/assets/icons/starSelected.svg" class="q-pe-md" />
    </div>

    <div class="col-lg-7 col-md-7 col-sm-10 q-pe-sm">
      <p>{{ panel.name }}</p>
      <div class="row q-mt-sm">
        <img class="panel-img" :src="textureUrl" />
        <div class="panel-details">
          <p>
            {{ Math.trunc(greaterPanelDimension * 1000) }}mm x
            {{ Math.trunc(lowerPanelDimension * 1000) }}mm
          </p>
        </div>
      </div>
    </div>
    <div
      class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center p-0"
    >
      <p v-if="selected" class="select">{{ $t("sidebar.selected") }}</p>
      <p
        v-else
        class="select select--green cursor-pointer"
        @click="handleSelectPanel"
      >
        {{ $t("sidebar.select") }}
      </p>
      <span v-if="editable && !anonymousUser">
        <img
          src="@/assets/icons/delete-panel.svg"
          class="q-ml-sm cursor-pointer"
          @click="handleDeletePanel"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SinglePanel",
  props: ["panel", "likedPanel", "selected", "anonymousUser", "editable"],
  emits: ["panelSelected", "starClicked", "panelUnliked"],
  computed: {
    textureUrl() {
      if (!this.panel.textureId) return "/assets/textures/texture-black.svg";
      return `/assets/textures/solar_panel_texture_half_cell_${this.panel.textureId}_horizontal.png`;
    },
    greaterPanelDimension() {
      return Math.max(this.panel.size.width, this.panel.size.height);
    },
    lowerPanelDimension() {
      return Math.min(this.panel.size.width, this.panel.size.height);
    },
  },
  methods: {
    handleSelectPanel() {
      this.$emit("panelSelected", this.panel);
    },
    handleStarClick() {
      this.$emit("starClicked", this.panel);
    },
    handleDeletePanel() {
      this.$emit("deletePanel", this.panel.id);
    },
    unlikePanel() {
      this.$emit("panelUnliked");
    },
  },
};
</script>

<style lang="scss" scoped>
.star-img {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.single-panel {
  background-color: #3b3c3d;
  border-radius: 3px;
  color: white;
}

.panel-details {
  background-color: #27292c;
  padding: 4px 14px;
  border-radius: 0 5px 5px 0;
}

.panel-img {
  width: 30px;
  border-radius: 10px;
  margin-right: -4px;
  z-index: 2;
}

.select {
  font-weight: 500;
  font-size: 14px;
  color: #d8d8d8;

  &--green {
    color: #23e7a5;
  }
}
</style>
