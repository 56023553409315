const decodeJwt = (jwt) => {
  if (typeof jwt !== "string") return null;

  let splitted = jwt.split(".");
  if (splitted.length < 2) return null;

  let obj1 = JSON.parse(atob(splitted[0]));
  let obj2 = JSON.parse(atob(splitted[1]));

  return Object.assign({}, obj1, obj2);
};

export { decodeJwt };
