<template>
  <div class="q-mb-md">
    <p class="title--panel q-mb-xs">
      {{ title }}
      <span>
        <el-tooltip
          class="bg-tooltip"
          effect="dark"
          :content="tooltipContent"
          placement="bottom"
        >
          <img
            src="@/assets/icons/info-icon.svg"
            class="q-ml-xs q-mb-xxs cursor-pointer"
          />
        </el-tooltip>
      </span>
    </p>
    <img :src="getImageUrl()" class="q-mr-sm" width="30" />
    <el-input-number
      :model-value="modelValue"
      type="number"
      class="input-field"
      controls-position="right"
      :precision="0"
      :min="0"
      :max="99"
      :value-on-clear="null"
      @update:modelValue="updateValue"
    >
      <template #increase-icon>
        <img src="@/assets/icons/arrow-up.svg" />
      </template>
      <template #decrease-icon>
        <img src="@/assets/icons/arrow-down.svg" />
      </template>
    </el-input-number>
  </div>
</template>

<script>
export default {
  name: "GridInputGroup",
  props: ["title", "tooltipContent", "iconSrc", "modelValue"],
  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value);
    },
    getImageUrl() {
      return new URL(this.iconSrc, import.meta.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  border-radius: 2px;
  width: 130px;
  color: white;
}

.input-field :deep(.el-input__wrapper) {
  position: relative;
  display: inline-block;
  box-shadow: none;
  background-color: #27292c;
  padding-left: 8px;
  padding-right: 74px;
}

.input-field :deep(.el-input__wrapper)::after {
  content: "cm";
  position: absolute;
  top: 50%;
  right: 64px;
  transform: translateY(-50%);
  font-size: 14px;
  color: white;
  pointer-events: none;
}

.input-field :deep(.el-input__inner) {
  color: white;
  text-align: center;
  border-radius: 2px !important;
}

.input-field :deep(.el-input-number__decrease) {
  box-shadow: none;
  background-color: #27292c;
  border-color: #333537 !important;
  color: white;
}

.input-field :deep(.el-input-number__increase) {
  box-shadow: none;
  background-color: #27292c;
  border-color: #333537 !important;
  color: white;
}

.is-error {
  border-color: red;
  color: red;
  border: 1px solid;
}

.title {
  color: white;
  font-weight: 600;
  font-size: 16px;

  &--panel {
    font-weight: 400;
  }

  &--red {
    color: #ff5050;
  }
}
</style>
