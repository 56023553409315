import Request from "../Request.js";

const projects = {
  getProject(id) {
    return Request.get(`/project/api/v1/projects/${id}`);
  },
  patchProject(id, data) {
    return Request.patch(`/project/api/v1/projects/${id}/`, data);
  },
  shareFastFusionSolarModel(data) {
    return Request.post(
      `/authentication/api/v1/share-fast-fusion-solar/`,
      data
    );
  },
};

export default projects;
