const state = {
  config: {
    project_id: null,
    solar_groups_visible: false,
    distances_visible: false,
    measurements_visible: false,
  },
};

const mutations = {
  SET_CONFIG(state, config) {
    state.config = { ...state.config, ...config };
  },
};

const actions = {
  setConfig({ commit }, config) {
    commit("SET_CONFIG", config);
  },
};

const getters = {
  getConfig: (state) => state.config,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
