import { ElMessage } from "element-plus";

export const showTokenInvalid = function () {
  ElMessage.error({
    message:
      "Token invalid. Please go to project page to generate a new token.",
    showClose: true,
  });
  if (this.anonymousUser) {
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}`;
    }, 3500);
  } else {
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/${this.projectId}`;
    }, 3500);
  }
};

export const verifyToken = function (payload) {
  if (this.projectId != payload.project_id) {
    this.showTokenInvalid();
    return;
  }
  const expirationDate = new Date(payload.exp * 1000);
  const now = new Date();
  if (now > expirationDate) {
    this.showTokenInvalid();
    return;
  }
};

export const isTokenExpired = function () {
  if (this.sample || this.anonymousUser) {
    return false;
  }
  const token = this.airteamViewerToken;
  if (!token || !token?.exp) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= token.exp;
};

export const checkTokenExpiration = function () {
  if (this.isTokenExpired()) {
    ElMessage.error({
      message:
        "Token expired. Please go to project page to generate a new token.",
      showClose: true,
    });
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/${this.projectId}`;
    }, 2500);
  }
};
