import { nextTick } from "vue";
import axios from "axios";
import moment from "moment";
import { createI18n } from "vue-i18n";
import { AVAILABLE_LOCALES } from "@/constants.js";
import deMessages from "@/locales/de.json";

const defaultLocale = "de";

function setI18nLanguage(lang) {
  moment.locale(lang);
  axios.defaults.headers.common["Accept-Language"] = lang;

  setTimeout(
    () => document.querySelector("html").setAttribute("lang", lang),
    300
  );
  i18n.global.locale.value = lang;
}

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  legacy: false,
  globalInjection: true,
  messages: Object.assign({ de: deMessages }),
});

const loadLocaleMessages = async (locale) => {
  if (!i18n.global.availableLocales.includes(locale)) {
    const messages = await import(`../locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages);
  }
  setI18nLanguage(locale);
  return nextTick();
};
loadLocaleMessages(defaultLocale);

export const changeLocale = async (lang) => {
  if (i18n.global.locale !== lang && AVAILABLE_LOCALES.includes(lang)) {
    await loadLocaleMessages(lang);
  }
};

window.i18n = i18n;
export default i18n;
