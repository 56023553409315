import * as THREE from "three";
import CameraControls from "camera-controls";
import { ZOOM_POINT_COLOR } from "@/constants";
import { RENDERING_ORDER } from "../constants";

export const enableControls = function () {
  this.cameraControls.enabled = true;
};

export const cleanNavigationSetup = function () {
  this.navigationMenu = null;
  this.disableDefaultNavigation();
};

export const restoreDefaultNavigation = function () {
  this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
};

export const disableDefaultNavigation = function () {
  this.cameraControls.mouseButtons.left = CameraControls.ACTION.NONE;
};

export const openHelpModal = function () {
  this.openHelperModal = true;
};

export const closeHelperModal = function () {
  this.openHelperModal = false;
};

export const toggleNavigation = function (value) {
  if (value === 0) {
    this.toggleActive(0);
    this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
  } else if (value === 1) {
    this.toggleActive(0);
    this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
    this.removeZoomCenterDot();
  }
  this.navigationMenu = value;
};

export const resetCameraPosition = function () {
  this.showGreenFullScreen = true;
  this.cameraControls.reset();
  this.camera.position.set(0, this.calculateModelHeight() * 2, 0);
  this.removeZoomCenterDot();
  setTimeout(() => {
    this.showGreenFullScreen = false;
  }, 1000);
};

export const addZoomCenter = function (event) {
  event.preventDefault();

  if (this.isCtrlDown || this.isAltDown || this.isShiftDown) return;

  if (event.target.tagName !== "CANVAS") return;

  let canvasBounds = this.renderer.getContext().canvas.getBoundingClientRect();
  this.mouse.x =
    ((event.clientX - canvasBounds.left) /
      (canvasBounds.right - canvasBounds.left)) *
      2 -
    1;
  this.mouse.y =
    -(
      (event.clientY - canvasBounds.top) /
      (canvasBounds.bottom - canvasBounds.top)
    ) *
      2 +
    1;

  this.mouse.x += 0.01;
  this.mouse.y -= 0.013;

  this.raycaster.setFromCamera(this.mouse, this.camera);

  // disable marker adding/connecting when drag is on
  if (this.dragOn) return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);

  if (intersects.length < 1) return;

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dotGeometry = new THREE.SphereGeometry(0.1, 64, 32);
  const dotMaterial = new THREE.MeshBasicMaterial({
    color: ZOOM_POINT_COLOR,
  });
  const dot = new THREE.Mesh(dotGeometry, dotMaterial);
  dot.position.x = pIntersect.x;
  dot.position.y = pIntersect.y;
  dot.position.z = pIntersect.z;

  this.cameraControls.moveTo(
    dot.position.x,
    dot.position.y,
    dot.position.z,
    true
  );

  dot.mousePosition = {
    x: this.mouse.x,
    y: this.mouse.y,
  };

  dot.material.depthTest = false;
  dot.renderOrder = RENDERING_ORDER.ZOOM_CENTER;

  this.removeZoomCenterDot();
  this.scene.add(dot);
  this.zoomCenterDot = dot;
};

export const removeZoomCenterEventListener = function () {
  window.removeEventListener("dblclick", this.addZoomCenter, false);
  this.removeZoomCenterDot();
};

export const removeZoomCenterDot = function () {
  if (this.zoomCenterDot) {
    const object = this.scene.getObjectById(this.zoomCenterDot.id);
    this.scene.remove(object);
  }
};

export const keyDownInit = function (event) {
  if (event.shiftKey && !this.isShiftDown) {
    this.isShiftDown = true;
    this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
    this.cameraControls.mouseButtons.middle = CameraControls.ACTION.TRUCK;
    this.cameraControls.mouseButtons.right = CameraControls.ACTION.TRUCK;
    this.removeZoomCenterDot();
  } else if (event.altKey && !this.isAltDown) {
    this.isAltDown = true;
    this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
  }
};

export const keyUpInit = function (event) {
  if (event.code === "Space") {
    this.resetCameraPosition();
  }
  if (!event.shiftKey && this.isShiftDown) {
    // if only shift key is released but the left mouse is stil down, a new click event is fired
    // this snippet is to prevent that extra event from causing a new point to be added
    // this is only relevant for measurement modes because that's when we have an preliminary line
    if (this.active === 4) {
      document.removeEventListener("click", this.addMeasurementPoint, false);
      document.addEventListener("click", this.reAddMeasurementPoint, false);
    } else if (this.active === 1) {
      document.removeEventListener("click", this.addMarker, false);
      document.addEventListener("click", this.reAddMarker, false);
    }

    this.isShiftDown = false;
    if (this.navigationMenu === 0) {
      this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    } else if (this.navigationMenu === 1) {
      this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
    } else {
      this.cameraControls.mouseButtons.left = this.currentLeftClickAction;
      this.cameraControls.mouseButtons.middle = CameraControls.ACTION.ROTATE;
      this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
    }
  } else if (!event.altKey && this.isAltDown) {
    // if only alt/options key is released but the left mouse is stil down, a new click event is fired
    // this snippet is to prevent that extra event from causing a new point to be added
    // this is only relevany for measurement modes because that's when we have an preliminary line
    if (this.active === 4) {
      document.removeEventListener("click", this.addMeasurementPoint, false);
      document.addEventListener("click", this.reAddMeasurementPoint, false);
    } else if (this.active === 1) {
      document.removeEventListener("click", this.addMarker, false);
      document.addEventListener("click", this.reAddMarker, false);
    }
    this.isAltDown = false;
    if (this.navigationMenu === 0) {
      this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    } else if (this.navigationMenu === 1) {
      this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
    } else {
      this.cameraControls.mouseButtons.left = this.currentLeftClickAction;
    }
  }
};

export const keyDownInitWindow = function (e) {
  if (e.code === "Space" && e.target === document.body) {
    e.preventDefault();
  }
};

export const hideAuxiliaryValuesOnZoom = function (e) {
  for (let area of this.measurementAreas) {
    const label = area.label;
    area.lines.forEach((line) => {
      const lineLabel = line.label;
      if (lineLabel) this.checkAuxiliaryOverlapWithMainLabel(label, lineLabel);
    });
  }
};
