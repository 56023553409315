import { createApp } from "vue";
import { LoadingBar, Quasar } from "quasar";
import ElementPlus from "element-plus";
import VueGtag from "vue-gtag";
import { H } from "highlight.run";
import i18n from "@/plugins/i18n";
import VueHtmlToPaper from "@/plugins/VueHtmlToPaper";
import { initializeSentry } from "@/plugins/sentry.js";
import store from "@/store/index.js";
import { isProduction } from "@/utils/env";

import "quasar/dist/quasar.css";
import "@quasar/extras/line-awesome/line-awesome.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@/scss/element-theme.scss";
import "@/scss/style.scss";

import App from "./App.vue";
import router from "./router";

H.init("neyxrjd4", {
  enableCanvasRecording: true,
  samplingStrategy: {
    canvasManualSnapshot: 2,
    canvasMaxSnapshotDimension: 480,
  },
});

const app = createApp(App);

app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

app
  .use(store)
  .use(router)
  .use(Quasar, {
    config: {
      brand: {
        // Colors from @/scss/_colors.scss
        primary: "#099483",
        secondary: "#DCA134",
        accent: "#65F8C6",
        dark: "#212121",
        positive: "#26A35E",
        negative: "#D92F39",
        info: "#734CC5",
        warning: "#F68720",
      },
      loadingBar: {
        color: "primary",
        hijackFilter: () => false,
      },
    },
    plugins: {
      LoadingBar,
    },
  })
  .use(i18n)
  .use(ElementPlus)
  .use(VueHtmlToPaper)
  .use(VueGtag, {
    config: {
      id: "G-VR76W3RV23",
    },
  })
  .mount("#app");

if (isProduction) {
  initializeSentry(app);
}
